import axios from "axios";
import { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { QueryClient } from "react-query/core";
import { toast } from "react-toastify";
import __ from "../config/translations/serbian";
import AuthContext from "../store/auth-context";
import { GlobalQueries } from "./useRefreshQuery";

class ApiService {
    /** The currently logged-in user. */
    user: ?{ token: string };

    /** The active react query client. */
    queryClient: QueryClient;

    /** Prepare the service. */
    constructor(user, queryClient) {
        this.user = user;
        this.queryClient = queryClient;
    }

    /**
     * Get the full URL from a relative path.
     *
     * @param {string} path The path to create the URL.
     *
     * @return {string} The full URL.
     */
    buildPath(path: string): string {
        return localStorage.getItem("api") + path.replace(/^\//, "");
    }

    /**
     * Execute the API call
     *
     * @param {"LIST","GET"|"PUT"|"POST"|"DELETE"} method The HTTP method to use.
     * @param {string} path The path to the API, without the domain and API version suffix.
     * @param {?{}} payload The payload to send in the request.
     * @param {boolean} returnPayload True to return payload part, false to return complete response.
     *
     * @return {Promise<APIResponse>}
     */
    _execute(method, path, payload = null, returnPayload: boolean = true) {
        return new Promise((resolve, reject) => {
            axios({
                method: method,
                url: this.buildPath(path),
                headers: { Authorization: `Bearer ${this.user?.token}` },
                data: payload,
            })
                .then((response) => resolve(returnPayload ? response.data.payload : response.data))
                .catch((error) => reject(error));
        });
    }

    /**
     * Execute the GET method.
     *
     * @param {string} path The path to the API, without the domain and API version suffix.
     * @param {boolean} returnPayload True to return payload part, false to return complete response.
     *
     * @return {Promise<APIResponse>}
     */
    get(path: string, returnPayload: boolean = true) {
        return this._execute("GET", path, null, returnPayload);
    }

    /**
     * Execute the PUT method.
     *
     * @param {string} path The path to the API, without the domain and API version suffix.
     * @param {?{}} payload The payload to send in the request.
     *
     * @return {Promise<APIResponse>}
     */
    put(path: string, payload: ?{} = null) {
        return this._execute("PUT", path, payload);
    }

    /**
     * Execute the POST method.
     *
     * @param {string} path The path to the API, without the domain and API version suffix.
     * @param {?{}} payload The payload to send in the request.
     *
     * @return {Promise<APIResponse>}
     */
    post(path: string, payload: ?{}, returnPayload: boolean = true) {
        return this._execute("POST", path, payload);
    }

    /**
     * Execute the LIST method.
     *
     * @param {string} path The path to the API, without the domain and API version suffix.
     * @param {?{}} payload The payload to send in the request.
     * @param {boolean} returnPayload True to return payload part, false to return complete response.
     *
     * @return {Promise<APIResponse>}
     */
    list(path: string, payload: ?{}, returnPayload: boolean = true) {
        return this._execute("LIST", path, payload, returnPayload);
    }

    /**
     * Execute the DELETE method.
     *
     * @param {string} path The path to the API, without the domain and API version suffix.
     *
     * @return {Promise<APIResponse>}
     */
    delete(path: string) {
        return this._execute("DELETE", path);
    }

    /**
     * Add a product to the current cart.
     *
     * @param {{}} product The product to add.
     * @param {number} quantity The number of items to add, or 0 to remove them.
     */
    globalAddToCart(product, quantity, loading, setLoading, setLoadingDelete = () => {}, loadingDelete, description = "", quantity_calc_type = "calc") {
        setLoading(true);
        this.post(
            `/cart`,
            {
                id_product: product,
                id_product_parent: null,
                quantity: quantity,
                description: null,
                quantity_calc_type: quantity_calc_type,
            },
            false
        )
            .then((response) => {
                this.queryClient.invalidateQueries(GlobalQueries.Cart).then();
                this.queryClient.invalidateQueries(GlobalQueries.Checkout).then();
                setLoading(false);

                // Show the appropriate message
                // let message = null;
                // switch (response?.message) {
                //     case "created":
                //         message = __.Cart.Messages.ProductHasBeenAddedToCart;
                //         break;
                //     case "updated":
                //         message = __.Cart.Messages.ProductHasBeenUpdatedInCart;
                //         break;
                //     case "removed":
                //         message = __.Cart.Messages.ProductHasBeenDeletedFromCart;
                //         break;
                //     default:
                //         break;
                // }

                // message && toast.success(message);

                toast.success(response.message);

                // toast.success("Uspešno ste dodali proizvod u korpu!");
            })
            .catch((error) => {
                console.error(error);
                toast.error(__.Messages.UnknownError);
                setLoading(false);
            });
    }

    /**
     * Add a product to the current cart.
     *
     * @param {{}} product The product to add.
     * @param {number} quantity The number of items to add, or 0 to remove them.
     */
    globalAddToCheckout(id_cart_item, quantity, loading, setLoading, setLoadingDelete = () => {}, loadingDelete, quantity_calc_type = "replace") {
        setLoading(true);
        this.put(`/checkout`, {
            cart_items_id: id_cart_item,
            quantity: quantity,
            quantity_calc_type: quantity_calc_type,
        })
            .then((response) => {
                this.queryClient.invalidateQueries(GlobalQueries.Checkout).then();
                this.queryClient.invalidateQueries(GlobalQueries.Cart).then();

                setLoading(false);

                response?.success && toast.success(__.Cart.Messages.ProductHasBeenUpdatedInCart);
            })
            .catch((error) => {
                console.error(error);
                toast.error(__.Messages.UnknownError);
                setLoading(false);
            });
    }

    /**
     * Add a product to the current cart.
     *
     * @param {{}} product The product to add.
     * @param {number} quantity The number of items to add, or 0 to remove them.
     */
    globalRemoveFromCheckout(id_cart_item, loading, setLoading, setLoadingDelete = () => {}, loadingDelete) {
        setLoading(true);
        this.delete(`/checkout/item/${id_cart_item}`, {})
            .then((response) => {
                this.queryClient.invalidateQueries(GlobalQueries.Checkout).then();
                this.queryClient.invalidateQueries(GlobalQueries.Cart).then();
                setLoading(false);

                console.log(response, "response");
                // Show the appropriate message
                // let message = null;
                // switch (response?.message) {
                //     case "created":
                //         message = __.Cart.Messages.ProductHasBeenAddedToCart;
                //         break;
                //     case "updated":
                //         message = __.Cart.Messages.ProductHasBeenUpdatedInCart;
                //         break;
                //     case "removed":
                //         message = __.Cart.Messages.ProductHasBeenDeletedFromCart;
                //         break;
                //     default:
                //         break;
                // }
                toast.success(response.message);
                // message && toast.success(message);
            })
            .catch((error) => {
                console.error(error);
                toast.error(__.Messages.UnknownError);
                setLoading(false);
            });
    }
    /**
     * Add a product to the current cart.
     *
     * @param {{}} product The product to add.
     * @param {int} insert True to insert into wishlist, or false to remove it.
     */
    globalAddToWishlist(product, insert) {
        this.post(`/wishlist`, {
            id_product: product,
            id_product_parent: null,
            in_wishlist: insert,
        })
            .then((response) => {
                this.queryClient.invalidateQueries(GlobalQueries.Wishlist).then();

                // Show the appropriate message
                let message = null;
                switch (response?.message) {
                    case "created":
                        message = __.Wishlist.Messages.ProductHasBeenAddedToWishlist;
                        break;
                    case "removed":
                        message = __.Wishlist.Messages.ProductHasBeenRemovedFromWishlist;
                        break;
                }
                message && toast.success(message);
            })
            .catch((error) => {
                console.error(error);
                toast.error(__.Messages.UnknownError);
            });
    }
}

/**
 * Get the reference to the API service.
 */
const useAPI = () => {
    const { user } = useContext(AuthContext);
    const queryClient = useQueryClient();
    return useState(new ApiService(user, queryClient))[0];
};

export default useAPI;
