import { Box } from "@mui/material";
import { Fragment } from "react";
import styles from "../DescriptionTabs.module.scss";

const SpecificationTab = ({ specification }) => {
  return (
    <Box className={styles.tabContent} sx={{ fontFamily: "Montserrat !important" }}>
      {(specification ?? []).map((data) => {
        return (
          <Box key={data?.set?.id}>
            {(Object.values(data.groups) ?? []).map((item) => {
              return (
                <Fragment key={item?.group?.id}>
                  {(Object.values(item.attributes) ?? []).map((attribute) => {
                    return (
                      <p key={attribute?.attribute?.id} className={styles.specificationRow}>
                        <span className={styles.specificationRowTitle}>{attribute?.attribute?.name}</span>
                        <span className={styles.specificationRowValue}>
                          {(attribute?.values ?? []).map((value, i) => {
                            if (i + 1 < attribute?.values.length) {
                              return value.name + ",";
                            } else {
                              return value.name;
                            }
                          })}
                        </span>
                      </p>
                    );
                  })}
                </Fragment>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default SpecificationTab;
