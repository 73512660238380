import { Box } from "@mui/material";
import icons from "helpers/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sitemap from "routes/sitemap";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import styles from "./SearchModal.module.scss";

const SearchModal = ({ openModal, setOpenModal }) => {
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    const submitHandler = (event) => {
        event.preventDefault();
        navigate(Sitemap.catalogue.search + "/" + search);
        setOpenModal(false);
    };

    return (
        <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} title="Pretraga">
            <form onSubmit={submitHandler} className={styles.search}>
                <input type="text" placeholder="Pronađite proizvod" className={styles.searchInput} value={search} onChange={(e) => setSearch(e.target.value)} />
                <button type="submit" className={styles.searchButton} onClick={submitHandler}>
                    <img src={icons.search} alt="search" />
                </button>
            </form>
        </ModalWrapper>
    );
};

export default SearchModal;
