import Empty from "../../../../components/Empty/Empty";
import __ from "../../../../config/translations/serbian";
import { ProductsView } from "../Controls/Controls";
import ProductGridItem from "./ProductGridItem/ProductGridItem";
import ProductListItem from "./ProductListItem/ProductListItem";

import scss from "./ProductItems.module.scss";

/**
 * Show the list of products.
 *
 * @param {{}[]} products The products to show.
 * @param {"grid"|"list"} view The type of the view to show products as.
 * @param {"single"|"double"} rows Limit the number of rows to show.
 *
 * @return {JSX.Element|null}
 * @constructor
 */

const ProductItems = ({ products, view = ProductsView.Grid, rows = false, location }) => {
  // Guard: Products supplied
  if (!products) {
    return null;
  }

  // Empty
  if (products.length === 0) {
    return <Empty message={__.Catalogue.ThereAreNoProductsToShow} />;
  }

  // Prepare the class name based on the view type
  const className = [scss[view]];

  // Show only the first couple of rows
  if (rows && scss[rows]) {
    className.push(scss[rows]);
  }

  return (
    <div className={className.join(" ")}>
      {products.map((product) =>
        view === ProductsView.List ? <ProductListItem key={product?.id} product={product} /> : <ProductGridItem key={product?.basic_data?.id_product} product={product} location={location} />
      )}
    </div>
  );
};

export default ProductItems;
