import icons from "helpers/icons";
import __ from "../../../config/translations/serbian";
import scss from "./BasicTable.module.scss";
import Variants from "components/Variants/Variants";

const BasicTable = ({ product, productId, updateProductVariant, handleURLChange, productVariant }) => {
    return (
        <div className={scss.container}>
            <div className={scss.wrapper}>
                <span className={`${scss.data}`}>
                    <span className={scss.dataTitle}>{__.Product.Column.Code}:</span>
                    <span className={scss.dataValue}>{productVariant === null || productVariant.length === 0 ? product?.item?.basic_data?.sku : productVariant?.basic_data?.sku}</span>
                </span>
                {product?.item?.basic_data?.barcode && (
                    <span className={`${scss.data} mobile-hidden`}>
                        <span className={scss.dataTitle}>{__.Product.Column.Barcode}:</span>
                        <span className={scss.dataValue}>{productVariant === null || productVariant.length === 0 ? product?.item?.basic_data?.barcode : productVariant?.basic_data?.barcode}</span>
                    </span>
                )}
                <span className={`${scss.data} ${scss.status}`}>
                    <span className={scss.dataTitleImage}>
                        <img
                            src={
                                productVariant === null || productVariant.length === 0
                                    ? product?.item?.inventory?.amount > 0
                                        ? icons.checked
                                        : icons.remove
                                    : productVariant?.inventory?.amount > 0
                                    ? icons.checked
                                    : icons.remove
                            }
                            alt=""
                        />
                    </span>
                    <span className={scss.dataValue}>{productVariant === null || productVariant.length === 0 ? product?.item?.inventory?.status : productVariant?.inventory?.status}</span>
                </span>
            </div>
            <div className={scss.wrapper}>
                <span className={`${scss.data} `}>
                    <span className={scss.dataTitle}>{__.Product.Column.Lager}:</span>
                    <span className={scss.dataValue}>
                        {productVariant === null || productVariant?.length === 0 ? (
                            <>{product?.item?.inventory?.amount > 0 ? product?.item?.inventory?.amount : "-"}</>
                        ) : (
                            <>{productVariant?.inventory?.amount > 0 ? productVariant?.inventory?.amount : "-"}</>
                        )}
                        {product?.item?.inventory?.unit}
                    </span>
                </span>
            </div>
            <div className={scss.wrapper}>
                <Variants firstVariantOption={false} product={product} productSlug={productId} handleURLChange={handleURLChange} updateProductVariant={updateProductVariant} />
            </div>
            {product?.item?.basic_data?.short_description && (
                <div className={scss.wrapper}>
                    <span className={`${scss.data}`}>
                        <span
                            className={scss.dataValue}
                            style={{ overflow: "hidden", lineHeight: "initial", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "2", lineClamp: 3, WebkitBoxOrient: "vertical" }}
                        >
                            {product?.item?.basic_data?.short_description}
                        </span>
                    </span>
                </div>
            )}
        </div>
    );
};

export default BasicTable;
