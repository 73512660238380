import PlusMinusInput from "components/Form/PlusMinusInput/PlusMinusInput";
import { currencyFormat } from "helpers/functions";
import useAPI from "hooks/useAPI";
import styles from "./CheckoutTable.module.scss";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import DeleteDialog from "../../../components/Dialogs/DeleteDielog";

const ProductRow = ({ item, showImage = true }) => {
  const location = useLocation();
  const api = useAPI();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false)
  // Track the quantity to add to the cart
  const rabateCalc = (item) => {
    let rabate = [];
    item?.product?.price?.per_item?.rebate?.active && item?.product?.price?.per_item?.rebate?.rebates?.map((rebate) => {
      rabate.push(rebate?.calc?.calc_name);
      return null
    });
    return rabate.join("; ");
  };

  const setQuantity = (quantity) => {
    api.globalAddToCheckout(item?.cart?.cart_item_id, quantity, loading, setLoading, loadingDelete, setLoadingDelete);
  };

  return (
    <>
      <tr className={`relative`} style={{ borderWidth: "5px", borderColor: "transparent", borderStyle: "solid" }}>
        {/*{showImage && <td className={styles.tcImage}>{item.product.image ? <img src={item.product.image} alt=""/> : "-"}</td>}*/}
        <td className={styles.tcCode}>{item?.product?.basic_data?.sku}</td>
        <td className={styles.tcTitle}>
          <Link to={`/katalog/artikal/${item?.product?.id}`} className={styles.productName}>
            {item?.product?.basic_data?.name}&nbsp;
            {item?.product?.attributes_text && <>&nbsp;({item?.product?.basic_data?.attributes_text})&nbsp;</>}
          </Link>
        </td>
        <td className={styles.rebate1}>{currencyFormat(item?.product?.price?.per_item?.price_with_out_vat, item?.product?.price?.currency)}</td>
        <td className={styles.rebate2}>{item?.product?.price?.per_item?.rebate?.active ? rabateCalc(item) : "/"}</td>
        <td className={styles.rebate2}>{item?.product?.price?.per_item?.discount?.active ? '-' + currencyFormat(item?.product?.price?.per_item?.discount?.amount, item?.product?.price?.currency) : "/"}</td>
        <Tooltip
          placement={"top"}
          arrow={true}
          title={
            <Box>
              <Box>
                Regularna cena: <span>{currencyFormat(item?.product?.price?.per_item?.price_with_out_vat)}</span>
              </Box>
              {item?.product?.price?.per_item?.rebate?.active &&
                item?.product?.price?.per_item?.rebate?.rebates?.map((rebate) => {
                  return (
                    <Box key={item?.product?.id}>
                      Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                    </Box>
                  );
                })}
              {item?.product?.price?.per_item?.discount?.active &&
                item?.product?.price?.per_item?.discount?.campaigns?.map((campaign) => {
                  return (
                    <Box key={item?.product?.id}>
                      Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                    </Box>
                  );
                })}
            </Box>
          }
        >
          <td className={`${styles.tcPrice} ${item?.product?.price?.per_item?.discount?.active ? styles.discounted : ''} ${item?.product?.price?.per_item?.rebate?.active ? styles.rebateStyle : ''}  ${item?.product?.price?.per_item?.discount?.active && item?.product?.price?.per_item?.rebate?.active ? styles.discountRabate : ''}`}>{currencyFormat(item?.product?.price?.per_item?.price_subtotal, item?.product?.price?.currency)}</td>
        </Tooltip>

        <td className={styles.tcInt}>
          <div className={location.pathname === "/korpa/potvrda" ? styles.plusminus : styles.plusminus1}>
            <PlusMinusInput quantity={item?.cart?.quantity} setQuantity={setQuantity} debounceId={item?.product?.id} loading={loading} setLoading={setLoading} />
          </div>
        </td>
        <td className={styles.tcPrice2}>{currencyFormat(item?.product?.price?.cost?.subtotal, item?.product?.price?.currency)}</td>
        <td className={styles.tcActions}>
          <i
            style={{ cursor: "pointer" }}
            className="fa fa-trash"
            onClick={() => {
              setShowDialog(true);
            }}
          />
        </td>
        {loadingDelete && <div className={styles.loading}></div>}
      </tr>
      <DeleteDialog
        openDeleteDialog={{ show: showDialog }}
        setOpenDeleteDialog={() => setShowDialog(false)}
        handleConfirm={() => {
          setLoadingDelete(true);
          api.globalRemoveFromCheckout(item?.cart?.cart_item_id, loading, setLoading, loadingDelete, setLoadingDelete);
          setShowDialog(false);
        }}
      />
    </>
  );
};

export default ProductRow;
