import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import PaginationUI from "components/UI/PaginationUI.js";
import LoadingWrapper from "../../../components/Loading/LoadingWrapper";
import { arraysAreEqual } from "../../../helpers/data";
import useAPI from "../../../hooks/useAPI";
import Banners from "../Banners/Banners";
import Controls, { DefaultProductsControls } from "./Controls/Controls";
import ProductItems from "./ProductItems/ProductItems";

import scss from "./Products.module.scss";

/**
 * Load products from the API and show controls.
 *
 * @param {string} url The URL to fetch the products from.
 * @param {?string} title The optional title to show, or null to hide the controls.
 * @param {?string} search The search term to apply.
 * @param {boolean} banners True to show banners above the products, false to hide them.
 * @param {{tag: number, array: []}} filters The filters to apply (with 'tag' that should help with state refresh).
 * @param {[]} availableFilters The list of currently available filters.
 * @param {function([])} setAvailableFilters Which filters to show.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Products = ({
  title,
  url,
  banners,
  search,
  selectedFilters,
  setSelectedFilters,
  availableFilters,
  setAvailableFilters,
  setActiveFilters,
  location,
  sort,
  setSort,
  currentPage,
  setCurrentPage = () => { },
  setNewQuery,
  newQuery,
  limit,
  setLimit = () => { },
}) => {
  const api = useAPI();
  // Number of current page

  // The control of the current presentation
  const [controls: { page: int, limit: int, view: string, order: string, ready: boolean }, setControls] = useState(DefaultProductsControls);
  // Reset page on the following
  useEffect(() => setControls((controls) => ({ ...controls, page: 1 })), [url, limit, sort, search]);

  // Load the products and refresh on any change from the controls
  const {
    data: products,
    isLoading,
    refetch,
  } = useQuery([url, controls.page, limit, sort, search, selectedFilters], () =>
    api.list(url, {
      search,
      filters: selectedFilters,
      page: currentPage,
      limit: limit,
      sort: sort,
    })
  );

  // Update available filters
  if (setAvailableFilters && products?.filters && !arraysAreEqual(availableFilters, products.filters)) {
    setAvailableFilters(products?.filters);
  }

  // Update the pagination
  // useEffect(() => setCurrentPage(products?.pagination.selected_page ?? 1), [products]);
  const setSorting = (obj) => {
    setSort({ field: obj.field, direction: obj.direction });
  };
  return (
    <div className={scss.wrapper}>
      {/* Show banners */}
      {banners && <Banners />}

      {/* Show optional controls */}
      {title !== null && (
        <Controls
          title={title}
          onParamChange={setControls}
          filters={availableFilters}
          setActiveFilters={setActiveFilters}
          numberOfProducts={products?.pagination.total_items ?? 0}
          setSorting={setSorting}
          sort={sort}
          setLimit={setLimit}
          limit={limit}
          setCurrentPage={setCurrentPage}
        />
      )}

      {/* Load and show the products */}
      <LoadingWrapper isLoading={isLoading || !controls.ready}>
        <ProductItems products={products?.items} view={controls.view} location={location} />

        <PaginationUI
          activePage={Number(currentPage)}
          setCurrentPage1={setCurrentPage}
          numberOfDisplayed={limit}
          numberOfProducts={products?.pagination.total_items ?? 0}
          changePage={(page) => setControls((controls) => ({ ...controls, page }))}
        />
      </LoadingWrapper>
    </div>
  );
};

export default Products;
