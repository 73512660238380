import LinkList from "../LinksList/LinkList";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

import icons from "helpers/icons";
import Sitemap from "routes/sitemap";

const UserPanelModal = ({ openModal, setOpenModal }) => {
    const links = [
        { icon: icons.user_profile, label: "Korisnički profil", to: Sitemap.profile._ },
        { icon: icons.cart, label: "Pregled kupovina", to: Sitemap.orders._ },
        { icon: icons.analytics, label: "Analitička kartica", to: Sitemap.static.analyticCard },
        { icon: icons.reclamations, label: "Reklamacije", to: Sitemap.static.reclamations },
        { icon: icons.report_problem, label: "Prijavite problem", to: Sitemap.static.reportProblem },
        { icon: icons.privacy_policy, label: "Politika privatnosti", to: Sitemap.static.privacyPolicy },
        { icon: icons.manual, label: "Uputstvo za portal", to: Sitemap.static.manual },
        { icon: icons.contact, label: "Kontakt", to: Sitemap.contact._ },
        { icon: icons.faq, label: "Najčešća pitanja", to: Sitemap.static.faq },
        { icon: icons.agreement, label: "Ugovor o saradnji", to: Sitemap.static.agreement },
    ];
    return (
        <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} title="Korisnički panel">
            <LinkList links={links} setOpenModal={setOpenModal} />
        </ModalWrapper>
    );
};

export default UserPanelModal;
