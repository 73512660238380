import { Link } from "react-router-dom";
import { join } from "../../../helpers/functions";
import LoadingWrapper from "../../Loading/LoadingWrapper";
import Icon from "@mui/material/Icon";
import scss from "./Button.module.scss";

/**
 * Render a standardized button.
 *
 * @param {string} label The label of the button.
 * @param {string} to The internal link to navigate to when clicked.
 * @param {"button"|"submit"} type The type of the button to render.
 * @param {string} className Additional className.
 * @param {"fill"|"outline"} variant The type of the button to render.
 * @param {function(event)} onClick Any action to perform when button is clicked.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Button = ({ icon, label, to, onClick, className, variant = "filled", type = "button", loading, styleLinkButton = false, disabled = false, buttonStyleBasic }) =>
  to ? (
    <Link
      className={join([scss.button, scss[variant], className])}
      to={to}
      onClick={onClick ? onClick : () => null}
      style={styleLinkButton ? styleLinkButton : { display: "flex", alignItems: "center" }}
    >
      {icon && <Icon sx={{ marginRight: "0.5rem", display: "flex" }}>{icon}</Icon>}
      {label}
    </Link>
  ) : (
    <button
      type={type}
      className={join([scss.button, scss[variant], className])}
      disabled={loading ? true : disabled}
      onClick={loading ? null : onClick}
      style={{ display: "flex", alignItems: "center", ...buttonStyleBasic }}
    >
      <LoadingWrapper isLoading={loading} type="spin" size={20} color={"#fff"} className={loading ? `${scss.loading}` : `${scss.wrapper} ${scss.wrappernopadding}`}></LoadingWrapper>
      {icon && <Icon sx={{ marginRight: "0.5rem", display: "flex" }}>{icon}</Icon>}
      <div className={loading ? scss.loading2 : ``}>{label}</div>
    </button>
  );

export default Button;
