import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import styles from "../DescriptionTabs.module.scss";

const DocumentsTable = ({ documents }) => {
    console.log("Documents::", documents);
    return (
        <>
            <Table className={styles.documentsTable} sx={{ fontFamily: "Montserrat !important" }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontFamily: "Montserrat !important" }}>Naslov</TableCell>
                        <TableCell sx={{ fontFamily: "Montserrat !important" }}>Opis</TableCell>
                        <TableCell sx={{ fontFamily: "Montserrat !important" }}>Dokument</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(documents ?? []).map((document) => {
                        return (
                            <TableRow key={document.id}>
                                <TableCell sx={{ fontFamily: "Montserrat !important" }}>{document?.title ?? document?.file_filename}</TableCell>
                                <TableCell sx={{ fontFamily: "Montserrat !important" }}>{document?.description}</TableCell>
                                <TableCell sx={{ fontFamily: "Montserrat !important" }}>
                                    <a href={document?.file} target="_blank" rel="noreferrer" download>
                                        Preuzmite
                                    </a>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

const DocumentsTab = ({ technicalDoc, instructionDoc, certificateDoc }) => {
    console.log("DocumentsTab:::::",technicalDoc)
    return (
        <Box className={styles.tabContent} sx={{ fontFamily: "Montserrat !important" }}>
            {technicalDoc.length > 0 && (
              <>
                <h6 className={styles.tabTitle}>Tehnička dokumentacija</h6>
                <Box className={styles.tabSection}>
                  <DocumentsTable documents={technicalDoc} />
                </Box>
              </>
            )}
            {instructionDoc.length > 0 && (
                <>
                    <h6 className={styles.tabTitle}>Instrukcije</h6>
                    <Box className={styles.tabSection}>
                        <DocumentsTable documents={instructionDoc} />
                    </Box>
                </>
            )}
            {certificateDoc.length > 0 && (
                <>
                    <h6 className={styles.tabTitle}>Sertifikati</h6>
                    <Box className={styles.tabSection}>
                        <DocumentsTable documents={certificateDoc} />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default DocumentsTab;
