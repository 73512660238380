import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useState } from "react";
import styles from "./CategoriesModal.module.scss";
import LinkList from "../LinksList/LinkList";
import { useQuery } from "react-query";
import useAPI from "hooks/useAPI";
import { useNavigate } from "react-router-dom";
import Sitemap from "routes/sitemap";
import icons from "helpers/icons";

const CategoriesModal = ({ openModal, setOpenModal }) => {
    const [search, setSearch] = useState("");
    const api = useAPI();
    const navigate = useNavigate();

    const submitHandler = (event) => {
        event.preventDefault();
        navigate(Sitemap.catalogue.search + "/" + search);
        setOpenModal(false);
    };

    const { data } = useQuery(["Products.CategoryTree"], () => api.get("/categories/product/tree"));

    const generateLinks = (categories, startPath) => {
        if (categories == null) return;

        return categories.map((category) => {
            let path = `${startPath}/${category.slug}`;
            return { icon: category.icon, label: category.name, to: path, children: generateLinks(category.children, path) };
        });
    };

    const links = generateLinks(data, "/katalog");

    /* const links = (data ?? []).map((category) => {
        return { icon: searchImg, label: category.name, to: category.slug, cildren: category.children };
    }); */

    return (
        <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} title="Pregled proizvoda">
            <form className={styles.search} onSubmit={submitHandler}>
                <input type="text" placeholder="PRETRAŽITE KATEGORIJE" className={styles.searchInput} value={search} onChange={(e) => setSearch(e.target.value)} />
                <button type="submit" className={styles.searchButton}>
                    <img src={icons.search} alt="search" onClick={submitHandler} />
                </button>
            </form>
            <LinkList links={links} setOpenModal={setOpenModal} />
        </ModalWrapper>
    );
};

export default CategoriesModal;
