import { Box } from "@mui/system";
import { Link, useLocation } from "react-router-dom";
import LinkChildren from "./LinkChildren";

import styles from "./LinkList.module.scss";

const LinkList = ({ links, setOpenModal }) => {
    const { pathname } = useLocation();
    return (
        <Box className={styles.linkList}>
            {(links ?? []).map((link) => {
                if (link.children && link.children.length > 0) return <LinkChildren key={link.to} link={link} setOpenModal={setOpenModal} />;

                return (
                    <Link to={link.to} key={link.to} onClick={() => setOpenModal(false)} className={`${styles.link} ${pathname.includes(link.to) && styles.active}`}>
                        {link.icon && (
                            <Box className={styles.linkIcon}>
                                <img src={link.icon} alt="" />
                            </Box>
                        )}
                        <Box className={styles.linkLabel}>{link.label}</Box>
                    </Link>
                );
            })}
        </Box>
    );
};

export default LinkList;
