import { Box } from "@mui/material";
import Button from "components/Buttons/Button/Button";
import Filters from "pages/Catalogue/Aside/Filters/Filters";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

import styles from "./FiltersModal.module.scss";

const FiltersModal = ({ openModal, setOpenModal, filters, setActiveFilters }) => {
  return (
    <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} title="Odaberite filtere">
      <Box className={styles.container}>
        <Filters available={filters} setActive={setActiveFilters} />
        <Button label="Prikaži rezultate" onClick={() => setOpenModal(false)} className={styles.button} />
      </Box>
    </ModalWrapper>
  );
};

export default FiltersModal;
