import { Box, Dialog } from "@mui/material";
import Button from "components/Buttons/Button/Button";
import Buttons from "components/Buttons/Buttons/Buttons";
import LoadingWrapper from "components/Loading/LoadingWrapper";
import __ from "config/translations/serbian";
import useAPI from "hooks/useAPI";
import { GlobalQueries } from "hooks/useRefreshQuery";
import EmptyPage from "pages/Empty/EmptyPage";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import Sitemap from "routes/sitemap";

import styles from "./CartDialog.module.scss";
import scss from "../../pages/Cart/CartPage.module.scss";
import CartTable from "../../pages/Cart/CartTable/CartTable";
import MobileCart from "../../pages/Cart/MobileCart/MobileCart";

const CartDialog = ({ openDialog, setOpenDialog }) => {
  const api = useAPI();

  // Load all products in the cart
  const { data, isLoading } = useQuery([GlobalQueries.Cart], () => api.list(`/cart`));
  const items = data?.items ?? [];
  const cost = data?.summary ?? [];
  const closeDialog = () => {
    setOpenDialog(false);
  };
  const getDialogContent = () => {
    // Show empty page
    if (!isLoading && items.length === 0) {
      return <EmptyPage slug={`cart-empty-modal`} message={__.EmptyPage.Templates.Cart} className={styles.emptyPage} setOpenDialog={setOpenDialog} />;
    }
    return (
      <LoadingWrapper isLoading={isLoading}>
        <div className={`${scss.cart} ${scss.section}`}>
          <div className="mobile-hidden">
            <div className={styles.cartTableHolder}>
              <CartTable items={items} showImage={false} showActions={false} cost={cost} maxHeight="50vh" setOpenDialog={setOpenDialog} /></div>
          </div>
          <div className="desktop-hidden">
            <MobileCart items={items} showImage={true} showActions={false} cost={cost} maxHeight="50vh" />
          </div>
        </div>
      </LoadingWrapper>
    );
  };
  const { pathname } = useLocation();
  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xxl" fullWidth={true}>
      <Box className={styles.container}>
        <div>
          <Box className={styles.content}>
            <h4 className={`${styles.title} m h-underline`}>{__.Cart.MenuTitle}</h4>
            {getDialogContent()}
          </Box>
        </div>
        <Box>
          <Buttons align="center">
            {pathname?.includes("/korpa/potvrda") ? (
              <Button label={__.Cart.Continue} to={"/pocetna"} className={styles.continue} onClick={closeDialog} />
            ) : (
              <Button label={__.Cart.Continue} onClick={closeDialog} className={styles.continue} />
            )}

            {items.length > 0 && <Button label={__.Cart.FinalizeThePurchase} to={Sitemap.cart.checkout} className={styles.checkout} onClick={closeDialog} />}
          </Buttons>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CartDialog;
