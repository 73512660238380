import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import AddToCartButton from "../../../../../components/Form/AddToCartButton/AddToCartButton";
import FormWrapper, { preventDefault } from "../../../../../components/Form/FormWrapper";
import PlusMinusInput from "../../../../../components/Form/PlusMinusInput/PlusMinusInput";
import { currencyFormat } from "../../../../../helpers/functions";
import { missingImageURL } from "../../../../../helpers/images";
import useAPI from "../../../../../hooks/useAPI";
import Sitemap from "../../../../../routes/sitemap";

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import scss from "./ProductListItem.module.scss";
import { toast } from "react-toastify";

const ProductListItem = ({ product }) => {
  const api = useAPI();
  const navigate = useNavigate();
  // Track the quantity to add to the cart
  const [quantity, setQuantity] = useState(1);

  const getQuantity = () => {
    if (quantity === 0) {
      setQuantity(1);
      return 1;
    }

    return quantity;
  };

  const [loading, setLoading] = useState(false);
  const [isPlusMinusInputEmpty, setIsPlusMinusInputEmpty] = useState(false);

  const renderPrices = (item) => {

    switch (item.product_type) {
      case "variant":
        switch (item?.price?.discount?.active || item?.price?.rebate?.active) {
          case true:
            switch (item?.price?.min?.price?.original === item?.price?.max?.price?.original) {
              case true:
                return (
                  <>
                    <Tooltip
                      placement={"top"}
                      arrow={true}
                      title={
                        <Box>
                          <Box>
                            Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                          </Box>

                          {item?.price?.rebate?.active &&
                            item?.price?.rebate?.rebates?.map((rebate) => {
                              return (
                                <Box key={item.id}>
                                  Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                                </Box>
                              );
                            })}

                          {item?.price?.discount?.active &&
                            item?.price?.discount?.campaigns?.map((campaign) => {
                              return (
                                <Box key={item.id}>
                                  Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                                </Box>
                              );
                            })}
                        </Box>
                      }>
                      <span className={`${scss.price} text-[1rem] self-start font-normal py-2 ${item?.price?.discount?.active ? scss.discounted : ''} ${item?.price?.rebate?.active ? scss.rebateStyle : ''}  ${item?.price?.discount?.active && item?.price?.rebate?.active ? scss.discountRabate : ''}`}>{currencyFormat(item?.price?.price?.discount)} </span>
                    </Tooltip>
                  </>
                );
                break;
              case false:
                return (
                  <>
                    <Tooltip
                      placement={"top"}
                      arrow={true}
                      title={
                        <Box>
                          <Box>
                            Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                          </Box>

                          {item?.price?.rebate?.active &&
                            item?.price?.rebate?.rebates?.map((rebate) => {
                              return (
                                <Box key={item.id}>
                                  Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                                </Box>
                              );
                            })}

                          {item?.price?.discount?.active &&
                            item?.price?.discount?.campaigns?.map((campaign) => {
                              return (
                                <Box key={item.id}>
                                  Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                                </Box>
                              );
                            })}
                        </Box>
                      }>
                      <span className={`text-[1rem] self-start ${scss.discount} font-normal py-2 ${scss.price} ${scss.tcPrice} ${item?.price?.discount?.active ? scss.discounted : ''} ${item?.price?.rebate?.active ? scss.rebateStyle : ''}  ${item?.price?.discount?.active && item?.price?.rebate?.active ? scss.discountRabate : ''}`}>
                        {currencyFormat(item?.price?.min?.price?.discount)} - {currencyFormat(item?.price?.max?.price?.discount)}
                      </span>
                    </Tooltip>
                  </>
                );
                break;
            }
          case false:
            switch (item?.price?.min?.price?.original === item?.price?.max?.price?.original) {
              case true:
                return (
                  <>
                    <span>
                      {currencyFormat(item?.price?.min?.price?.original)}
                    </span>
                  </>
                );
                break;
              case false:
                return (
                  <>
                    <span >
                      {currencyFormat(item?.price?.min?.price?.original)} - {currencyFormat(item?.price?.max?.price?.original)}
                    </span>
                  </>
                );
                break;
            }
        }
        break;
      case "single":
        switch (true) {
          case item?.price?.discount?.active && item?.price?.rebate?.active:
            return (
              <>
                <Tooltip
                  placement={"top"}
                  arrow={true}
                  title={
                    <Box>
                      <Box>
                        Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                      </Box>

                      {item?.price?.rebate?.active &&
                        item?.price?.rebate?.rebates?.map((rebate) => {
                          return (
                            <Box key={item.id}>
                              Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                            </Box>
                          );
                        })}

                      {item?.price?.discount?.active &&
                        item?.price?.discount?.campaigns?.map((campaign) => {
                          return (
                            <Box key={item.id}>
                              Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                            </Box>
                          );
                        })}
                    </Box>
                  }>
                  <span className={`text-[1rem] self-start font-normal py-2 ${scss.discountRabate}`}>{currencyFormat(item?.price?.price?.discount)}</span>
                </Tooltip>
              </>
            );
            break;
          case item?.price?.discount?.active && !item?.price?.rebate?.active:
            return (
              <>
                <Tooltip
                  placement={"top"}
                  arrow={true}
                  title={
                    <Box>
                      <Box>
                        Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                      </Box>

                      {item?.price?.rebate?.active &&
                        item?.price?.rebate?.rebates?.map((rebate) => {
                          return (
                            <Box key={item.id}>
                              Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                            </Box>
                          );
                        })}

                      {item?.price?.discount?.active &&
                        item?.price?.discount?.campaigns?.map((campaign) => {
                          return (
                            <Box key={item.id}>
                              Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                            </Box>
                          );
                        })}
                    </Box>
                  }>
                  <span className={`text-[1rem] self-start font-normal py-2 ${scss.tcPrice} ${scss.discounted}`}>{currencyFormat(item?.price?.price?.discount)}</span>
                </Tooltip>
              </>
            );
            break;
          case !item?.price?.discount?.active && item?.price?.rebate?.active:
            return (
              <>
                <Tooltip
                  placement={"top"}
                  arrow={true}
                  title={
                    <Box>
                      <Box>
                        Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                      </Box>

                      {item?.price?.rebate?.active &&
                        item?.price?.rebate?.rebates?.map((rebate) => {
                          console.log("Rebate", rebate)
                          return (
                            <Box key={item.id}>
                              Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                            </Box>
                          );
                        })}

                      {item?.price?.discount?.active &&
                        item?.price?.discount?.campaigns?.map((campaign) => {
                          return (
                            <Box key={item.id}>
                              Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                            </Box>
                          );
                        })}
                    </Box>
                  }>
                  <span className={`text-[1rem] self-start font-normal py-2 ${scss.rebateStyle}`}>{currencyFormat(item?.price?.price?.discount)}</span>
                </Tooltip>
              </>
            );
            break;
          default:
            return (
              <>
                <span>
                  {item?.price?.price?.original !== 0 ? currencyFormat(item?.price?.price?.original) : "-"}
                </span>
              </>
            );
            break;
        }
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }} className={`${scss.wrapper} ${scss.wrapperBoxMobile}`}>
        <Link
          tabIndex={-1}
          to={Sitemap.catalogue.productDetails + "/" + product?.id}
          className={scss.wrapperLink}
        >
          {/* Image and name */}
          <img className={scss.image} src={product?.images[0] ?? missingImageURL()} alt={product?.name} />
          <img className={scss.imageHover} src={product?.images[0] ?? missingImageURL()} alt={product?.name} />
          <div className={scss.sku}>{product?.basic_data?.sku}</div>
          <div className={scss.name}>{product?.basic_data?.name}</div>
        </Link>
        <div className={scss.data}>
          <div className={scss.price}>{product?.price?.price_defined ? <>{renderPrices(product)}</> : <>/</>}</div>
          <FormWrapper
            tabIndex={-1}
            className={scss.addToCart}
            onSubmit={() => {
              if (isPlusMinusInputEmpty) {
                toast.warn("Molimo unesite količinu!");
                setLoading(false);
              } else {
                api.globalAddToCart(product.basic_data?.id_product, getQuantity(), loading, setLoading);
                setQuantity(1)
              }

            }}
          >
            {product?.product_type === "variant" ? (
              <div onClick={() => { navigate(Sitemap.catalogue.productDetails + "/" + product?.id) }} className={`text-center w-auto   justify-content-center align-items-center  d-flex ${scss.button1}`}>Više opcija</div>
            ) : (
              <>
                {product?.price?.price_defined && product?.inventory.inventory_defined ? (
                  <>
                    <div className={scss.plusminusinput}>
                      <PlusMinusInput quantity={quantity} setQuantity={setQuantity} setIsPlusMinusInputEmpty={setIsPlusMinusInputEmpty} />
                    </div>

                    <AddToCartButton
                      loading={loading}
                      onClick={() => {
                        if (isPlusMinusInputEmpty) {
                          toast.warn("Molimo unesite količinu!");
                          setLoading(false);
                        } else {
                          api.globalAddToCart(product.basic_data?.id_product, getQuantity(), loading, setLoading);
                          setQuantity(1)
                        }
                      }}
                      className={scss.addToCartButton}
                      product={product}
                    />
                  </>
                ) : (
                  <div
                    onClick={() => {
                      navigate(`/kontakt?slug=${product?.basic_data?.slug}`);
                    }}
                    className={scss.upit}
                  >
                    Na upit
                  </div>
                )}
              </>
            )}
          </FormWrapper>
        </div>
      </Box>
      {/*<Link to={Sitemap.catalogue.productDetails + "/" + product?.id} className={scss.wrapperMobile}>*/}
      {/*    /!* Image and name *!/*/}
      {/*    <img className={scss.image} src={product?.images[0] ?? missingImageURL()} alt={product?.name} />*/}
      {/*    <img className={scss.imageHover} src={product?.images[0] ?? missingImageURL()} alt={product?.name} />*/}

      {/*    <div className={scss.data}>*/}
      {/*        <div className={scss.name}>{product?.basic_data?.name}</div>*/}
      {/*        <div className={scss.sku}>{product?.basic_data?.sku}</div>*/}
      {/*        <div className={scss.price}>*/}
      {/*            {renderPrices(product)} <PlusMinusInput quantity={quantity} setQuantity={setQuantity} className={scss.plusMinus} />*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <FormWrapper className={scss.addToCart} onSubmit={() => api.globalAddToCart(product?.basic_data?.id_product, getQuantity(), loading, setLoading)}>*/}
      {/*        {product?.product_type === "variant" ? (*/}
      {/*            <p className={"text-center"}>Više opcija</p>*/}
      {/*        ) : product?.product_type === "variant" ? null : (*/}
      {/*            <AddToCartButton type={product?.inventory?.amount || product?.item?.inventory <= 0 ? "submit" : "button"} product={product} />*/}
      {/*        )}{" "}*/}
      {/*    </FormWrapper>*/}
      {/*</Link>*/}
    </>
  );
};

export default ProductListItem;
