import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import useAPI from "../../hooks/useAPI";
import ProductGridItem from "../Catalogue/Products/ProductItems/ProductGridItem/ProductGridItem";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import MainCard from "../../components/layout/MainCard/MainCard";

import scss from "./detailclasses.module.scss";

const PromoDetails = () => {
  const api = useAPI();
  const params = useParams();
  const slug = params?.id;

  const { data: basic_data, isFetching: loadingBasicData } = useQuery(["basicData", slug], async () => await api.get(`/landing-pages/basic-data/${slug}`), {
    refetchOnWindowFocus: false,
  });

  const { data: thumb, isFetching: loadingThumb } = useQuery(["thumbData", slug], async () => await api.list(`/landing-pages/thumb/${slug}`), {
    refetchOnWindowFocus: false,
  });

  const { data: conditions, isFetching: loadingConditions } = useQuery(["conditionsData", slug], async () => await api.list(`/landing-pages/conditions/${slug}`), {
    refetchOnWindowFocus: false,
  });

  return (
    <MainCard>
      <LoadingWrapper isLoading={loadingThumb || loadingConditions || loadingBasicData}>
        <div className={`mx-auto p-3 h-100 row ${scss.containered}`}>
          <div className={`mt-[3rem] md:mt-[9rem] pb-10`}>
            {basic_data?.name && (
              <div className={`text-left`}>
                <h1 className={`${scss.title}`}>{basic_data?.name}</h1>
              </div>
            )}
            <div className={`d-flex align-items-start flex-column justify-center`}>
              <div className={`relative w-100`}>
                {basic_data?.image && (
                  <div className={`relative`}>
                    <img src={basic_data?.image} alt={``} width={500} height={550} priority quality={100} style={{ objectFit: "cover" }} className={`w-100`} />
                  </div>
                )}
              </div>

              <div className={`${basic_data?.gallery?.length > 0 ? `grid grid-cols-2 gap-x-5 gap-y-5` : ``} mt-10`}>
                <div
                  className={`${basic_data?.gallery?.length > 0 && `col-span-1 deffont`}`}
                  dangerouslySetInnerHTML={{
                    __html: basic_data?.description,
                  }}
                />

                <div
                  className={`${basic_data?.gallery?.length > 0 ? `block` : `hidden`}
                            `}
                >
                  {/*<Swiper style={{ width: "40%" }} modules={[Pagination]} pagination={{ clickable: true }}>*/}
                  {/*    {data?.basic_data?.gallery?.map((image) => {*/}
                  {/*        return (*/}
                  {/*            <SwiperSlide>*/}
                  {/*                <img src={image?.image} alt={``} width={1920} height={263} priority quality={100} style={{ objectFit: "cover" }} className={`w-full h-auto`} />*/}
                  {/*            </SwiperSlide>*/}
                  {/*        );*/}
                  {/*    })}*/}
                  {/*</Swiper>*/}
                </div>
              </div>
            </div>
            <div className={`d-flex w-100 h-100 gap-5 flex-wrap justify-content-between`}>
              {conditions?.items?.map((condition) => {
                return <ProductGridItem product={condition} key={condition?.id} />;
              })}
            </div>
            <div className={`grid pb-5 grid-cols-2 w-full md:grid-cols-3 xl:grid-cols-4 gap-x-5 gap-y-5 mt-16`}>
              {thumb?.items?.map((item) => {
                return (
                  <div className={`${scss.promolist}`}>
                    <div className={`promolistwrapper`}>
                      <Link to={`/${item?.url}`} className={`promolistitem`} key={item.id}>
                        <div className={`promolistitemcontent`}>
                          <div className={`promolistitemcontenttitle`}>{item?.name}</div>
                          <div className={`promolistitemcontenttext`} dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                        </div>
                        <div className={`promolistitemimg`}>
                          <img src={item?.thumb_image} alt="" width={300} height={300} />
                        </div>
                        <Link to={`/${item?.url}`} className={`promolistitembutton mt-3 text-center w-100 block`}>
                          Saznajte više
                        </Link>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </MainCard>
  );
};

export default PromoDetails;
