import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import CreateForm from "components/Form/CreateForm/CreateForm";
import __ from "../../../config/translations/serbian";
import useAPI from "../../../hooks/useAPI";
import ContactPage from "../ContactPage";

import formFields from "./formFields.json";

const ContactForm = () => {
  const [data, setData] = useState({ title: "", message: "" });
  const [product, setProduct] = useState();
  const api = useAPI();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const slug = searchParams.get("slug");
  const execute = () => () => api.get(`/product-details/basic-data/${slug}`);
  const { data: basic, ...basicQuery } = useQuery([`Product:${slug}:Basic`, slug], execute());
  const setDatas = () => {
    if (basicQuery.isSuccess) {
      setProduct(basic.data);
    }
  };
  useEffect(() => {
    setDatas();
  }, [basicQuery.isSuccess]);

  useEffect(() => {
    if (product) {
      setData({
        title: `Upit za proizvod ${product?.item?.basic_data?.name} (${product?.item?.basic_data?.sku})`,
        message: `Poštovani, \n\n Molim Vas da na e-mail pošaljete ponudu za proizvod ${product?.item?.basic_data?.name} (${product?.item?.basic_data?.sku}). \n\n Hvala.`,
      });
    }
  }, [product]);

  return (
    <ContactPage>
      <div className="row p">
        <div className="">
          <h4 className="h-underline">{__.Contact.MenuTitle}</h4>

          <CreateForm
            loading={loading}
            data={data}
            setData={setData}
            formFields={formFields}
            submit={{
              label: "Pošaljite poruku",
              action: () => {
                if (data.title === "" || data.message === "") {
                  setLoading(false);
                } else {
                  setLoading(true);
                }
                if (data.title.trim() === "" || data.message.trim() === "") {
                  toast.warning(__.Contact.Messages.PleaseInertTitleAndMessage);
                  return;
                }

                api.post("/contact", data)
                  .then(() => {
                    toast.success(__.Contact.Messages.MessageHasBeenSent);
                    setData({});
                    setLoading(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    toast.error(__.Messages.UnknownError);
                    setLoading(false);
                  });
              },
            }}
          />
        </div>
      </div>
    </ContactPage>
  );
};

export default ContactForm;
