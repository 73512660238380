import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useQuery } from "react-query";

import MainCard from "../../components/layout/MainCard/MainCard";
import __ from "../../config/translations/serbian";
import Sitemap from "../../routes/sitemap";
import useArray from "../../hooks/useArray";
import CatalogueAside from "../Catalogue/Aside/CatalogueAside";
import Products from "../Catalogue/Products/Products";
import useAPI from "hooks/useAPI";

// import scss from "./NewPage.module.scss";
/**
 * The main page that shows multiple products.
 */
const NewPage = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const { searchTerm } = useParams();
  const { pathname } = useLocation();
  const trimmed = pathname.trim("/");
  const parts = trimmed.split("/");
  const category = parts[parts.length - 1];

  const { data: newPageList, isFetching } = useQuery(["NewPage"], () => api.list("/products/new-in/list"), {
    refetchOnWindowFocus: false,
  });

  // The available and active filters
  const [activeFilters, setActiveFilters] = useArray([]);
  const [availableFilters, setAvailableFilters] = useState([]);
  // The category we are searching for
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [changeFilters, setChangeFilters] = useState(false);
  // let title = data?.basic_data?.name;
  let title = "Novo u ponudi";
  let categoryId = activeCategory?.id ?? 0;
  let search = "";
  const {
    data: filters,
    isFetched,
    ...basic
  } = useQuery(["NewPage.Filters"], () => {
    return api.post(`/products/new-in/filters`, {
      filters: selectedFilters,
    });
  });

  useEffect(() => {
    if (changeFilters) {
      // Perform the query again with the updated filters
      basic.refetch({
        filters: selectedFilters ?? [],
      });
      setChangeFilters(false);
    }
  }, [changeFilters, selectedFilters]);
  //
  // // Navigate to category
  const navigateToCategory = (category) => {
    navigate(Sitemap.catalogue._ + (category?.path ?? ""));
    setActiveCategory(category);
  };
  //
  // // The used search term
  if (searchTerm) {
    title = `${__.Catalogue.Search}: "${searchTerm}"`;
    categoryId = 0;
    search = searchTerm;
  }

  const params = useParams();

  // useEffect(() => {
  //     const filtersQueryString = selectedFilters.map((filter) => `filter=${filter.value.selected}`).join("&");
  //     const newUrl = `${pathname}?${filtersQueryString}`;
  //     navigate(newUrl);
  // }, [selectedFilters, pathname, navigate]);

  return (
    <MainCard>
      {/* The list of available categories */}
      <CatalogueAside
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        onActivateCategory={navigateToCategory}
        availableFilters={filters}
        setActiveFilters={setActiveFilters}
        isFetched={isFetched}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        changeFilters={changeFilters}
        setChangeFilters={setChangeFilters}
      />

      <Products
        title={title}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        url={`/products/new-in/list`}
        banners={false}
        filters={activeFilters}
        availableFilters={availableFilters}
        setActiveFilters={setActiveFilters}
        setAvailableFilters={setAvailableFilters}
        location={location?.pathname}
      />
    </MainCard>
  );
};

export default NewPage;
