// import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useAPI from "../../hooks/useAPI";
import classes from "./StaticPage.module.css";
const StaticPage = ({ slug }) => {
    const api = useAPI();
    const { data } = useQuery(["staticPage" + slug], async () => await api.list(`/static-pages/content/${slug}`), {
        refetchOnWindowFocus: false,
    });

    const staticData = data?.items?.map((item) => {
        return item;
    });

    const keyGenerator = (prefix) => {
        return `${prefix}-${Math.random().toString(36)}`;
    };

    return (
        <div className={``}>
            {staticData?.map((item) => {
                switch (item?.type) {
                    case "multiple_images":
                        return (
                            <div key={keyGenerator("multiple_images")} className={`w-[90%] mt-3 d-flex align-items-center gap-3 flex-wrap`}>
                                {item?.content?.map((image) => {
                                    return (
                                        <div key={keyGenerator("image")} className={`d-flex justify-center col-span-1 relative `}>
                                            <div className={`h-280px h-sm-300px h-lg-450px h-2xl-500px`}>
                                                <img src={image?.file} alt={``} fill priority className={`w-100 h-auto ${classes.image}`} />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );

                        break;

                    case "html_editor":
                        return <div key={keyGenerator("html")} className={`w-[90%] mt-3 mx-auto prose !max-w-full`} dangerouslySetInnerHTML={{ __html: item?.content }}></div>;

                        break;

                    case "textarea":
                        return <div key={keyGenerator("textarea")} className={`w-[90%] mt-3 mx-auto prose !max-w-full`} dangerouslySetInnerHTML={{ __html: item?.content }}></div>;

                        break;
                }
            })}
        </div>
    );
};

export default StaticPage;
