import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import CreateForm from "components/Form/CreateForm/CreateForm";
import __ from "../../../config/translations/serbian";
import useAPI from "../../../hooks/useAPI";
import { GlobalQueries } from "../../../hooks/useRefreshQuery";
import ProfilePage from "../ProfilePage";
import AuthContext from "../../../store/auth-context";

import scss from "./CompanyProfilePage.module.scss";

import leftForm from "./leftFormFields.json";
import rightForm from "./rightFormFields.json";

const CompanyProfilePage = () => {
  const api = useAPI();
  const apiPath = "/profile/company";
  const authCtx = useContext(AuthContext);
  // Load all data
  const { data: companyData, isLoading: isCompanyLoading } = useQuery([GlobalQueries.Profile.CompanyProfile], () => api.get(`${apiPath}/basic-data`));
  const { data: addressData, isLoading: isAddressLoading } = useQuery([GlobalQueries.Profile.CompanyAddress], () => api.get(`${apiPath}/head-office-address`));

  // Local state for the company
  const [company, setCompany] = useState();
  useEffect(() => {
    setCompany(companyData);
  }, [companyData, setCompany]);

  // Local state for the company address
  const [address, setAddress] = useState();
  useEffect(() => {
    setAddress(addressData);
  }, [addressData, setCompany]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const handleUser = async () => {
    await api.get("/profile/user-data").then((response) => {
      console.log("response", response);
      if (response) {
        authCtx.refreshUser(response);
      }
    });
  };

  const submitHandlerCompany = () => {
    setLoading(true);
    api.post(`${apiPath}/basic-data`, company)
      .then((response) => {
        toast.success("Uspešno izmenjeni podaci!");
        handleUser();
        setLoading(false);
      })
      .catch(() => {
        toast.error(__.Messages.UnknownError);
        setLoading(false);
      });
  };

  const submitHandlerAddress = () => {
    setLoading1(true);
    api.post(`${apiPath}/head-office-address`, {
      object_number: null,
      floor: null,
      apartment_number: null,
      town_name: null,
      zip_code: null,
      id_municipality: null,
      municipality_name: null,
      country_name: null,
      country_id: updateCountryId ?? null,
      note: null,
      ...address,
    })
      .then((response) => {
        setAddress(response ?? {});
        toast.success("Uspešno izmenjeni podaci!");
        handleUser();
        setLoading1(false);
      })
      .catch(() => toast.error(__.Messages.UnknownError));
    if (authCtx.isLoggedIn) {
      api.get("/profile/user-data").then((response) => {
        if (response) {
          authCtx.refreshUser(response);
        }
      });
    }
  };
  const [updateCountryId, setUpdateCountryId] = useState(address?.id_country);
  // useEffect(() => {
  //     const townField = rightForm?.find((item) => {
  //         return item?.field === "id_town";
  //     });
  //
  //     const updatedCountryId = updateCountryId;
  //
  //     let url = townField.options.fillFromApi;
  //     url = url.split("?").shift();
  //     url += `?id_country=${updatedCountryId}`;
  //     townField.options.fillFromApi = url;
  // }, [updateCountryId]);
  return (
    <ProfilePage>
      <h4 className="h-underline">{__.Profile.SubPages.CompanyProfile.Title}</h4>

      <div className={scss.formsContainer}>
        {company && (
          <CreateForm
            loading={loading}
            title="Kompanija"
            data={company}
            setData={setCompany}
            formFields={leftForm}
            submit={{ label: "Sačuvaj", action: submitHandlerCompany }}
            className={scss.form}
          />
        )}
        {company && address && <span className={scss.formDivider} />}
        {address && (
          <CreateForm
            updateCountryId={updateCountryId}
            setUpdateCountryId={setUpdateCountryId}
            title="Sedište"
            loading={loading1}
            data={address}
            setData={setAddress}
            formFields={rightForm}
            submit={{ label: "Sačuvaj", action: submitHandlerAddress }}
            className={scss.form}

          />
        )}
      </div>
    </ProfilePage>
  );
};

export default CompanyProfilePage;
