import { useQuery } from "react-query";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import useAPI from "../../hooks/useAPI";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import CatalogueAside from "../Catalogue/Aside/CatalogueAside";
import MainCard from "../../components/layout/MainCard/MainCard";

import Typography from "@mui/material/Typography";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";

import scss from "./PromoList.module.scss";
import { Box } from "@mui/material";

const PromoList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeCategory, setActiveCategory] = useState(null);

  const navigateToCategory = (category) => {
    navigate(pathname === "/pocetna" ? "/pocetna" : "/katalog/kategorija" + pathname === "/pocetna" ? "" : "/" + category.slug);
    setActiveCategory(category);
  };

  const api = useAPI();

  const { data: landingPagesList, isFetching } = useQuery(["PromoPage"], () => api.list("/landing-pages/list"), {
    refetchOnWindowFocus: false,
  });

  return (
    <MainCard>
      <CatalogueAside activeCategory={activeCategory} setActiveCategory={setActiveCategory} onActivateCategory={navigateToCategory} />
      <LoadingWrapper isLoading={isFetching} className={scss.loaderPromoPage}>
        <div className={scss.promolist}>
          <div className={scss.promolistwrapper}>
            {landingPagesList?.items?.map((item) => {
              return (
                <Link to={`/promo/${item?.slug}`} className={scss.promolistitem} key={item.id}>
                  <div className={scss.promolistitemcontent}>
                    <div className={scss.promolistitemcontenttitle}>{item?.name}</div>
                    <div className={scss.promolistitemcontenttext} dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                  </div>
                  <div className={scss.promolistitemimg}>
                    {item?.image ?
                      <img src={item?.image} alt="" width={300} height={300} />
                      :
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "300px", height: "300px" }}>
                        <NoPhotographyIcon sx={{ color: "var(--croonus-lightGray)", fontSize: "4rem" }} />
                      </Box>
                    }
                  </div>
                  {/*< className={`promolistitembutton mt-3 text-center w-100 block`}>*/}
                  {/*    Saznajte više*/}
                  {/*</Link>*/}
                  <Typography variant="" sx={{ marginTop: "1rem", textAlign: "center", display: "flex", justifyContent: "center", "&:hover": { color: "var(--primary-color)" } }}>
                    Saznajte više
                  </Typography>
                </Link>
              );
            })}
          </div>
        </div>
      </LoadingWrapper>
    </MainCard>
  );
};

export default PromoList;
