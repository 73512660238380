import { useState } from "react";

import croonusLogo from "../../assets/images/logo/croonus-logo-menu-white.png";
import useAPI from "../../hooks/useAPI";

import CreateForm from "components/Form/CreateForm/CreateForm";
import Sitemap from "routes/sitemap";
import Loading from "components/Loading/Loading";

import scss from "./RegistrationPage.module.scss";
import formFields from "./formFields.json";
/**
 * The login page for the B2B.
 *
 * @return {JSX.Element}
 * @constructor
 */
const RegistrationPage = () => {
  const api = useAPI();

  const init = { company_name: null, email: null, maticni_broj: null, phone: null, pib: null, contact_person: null };

  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const submitHandler = () => {
    setIsLoading(true);
    api.post("customer/registration", { ...init, ...data })
      .then((response) => {
        setMessage("Uspešno ste poslali zahtev za registraciju. Kontaktiraćemo vas u najkraćem roku.");
        setIsLoading(false);
        setIsSent(true);
      })
      .catch((error) => {
        console.warn(error);
        setIsLoading(false);
        setMessage("Došlo je do nepoznate greške. Molimo pokušajte kasnije.");
      });
  };

  return (
    <div className={scss["login-container"]}>
      <div className={scss["login-card"]}>
        <div className={scss["background-wrapper"]}>
          <img src={api.buildPath("/configuration/images/presentation/login/image")} alt="" />
          <div />
        </div>
        <div className={`${scss["login-card-gray-overlay"]} col-xxl-6 col-lg-8 `}>
          {/* Background image */}
          <img src={api.buildPath("/configuration/images/presentation/login/logo")} className={scss["logo"]} alt="logo" />

          {/* Title, subtitle and description */}
          <h1 className={scss["company-title"]}>
            Postanite B2B korisnik
            {/*<span className={scss["big-dot"]}>.</span>*/}
          </h1>
          <h5 className={scss["login-sub-title"]}>
            Ako ste zainteresovani za naše proizvode, ne čekajte više. Jednostavno se registrujte tako što ćete popuniti zahtev i već danas možete da dobijete Vaš personalizovan nalog.
          </h5>
          <h5 className={scss["login-sub-title"]}>
            Već imate nalog? <a href={Sitemap.auth.login}>Ulogujte se</a>
          </h5>
          {!isLoading ? (
            <>
              {message && (
                <div className={scss.message}>
                  <p>{message}</p>
                </div>
              )}
              {!isSent && (
                <div className={" login-form-style"}>
                  <CreateForm data={data} setData={setData} formFields={formFields} className={scss.form} submit={{ label: "Pošaljite zahtev", action: submitHandler }} />
                </div>
              )}
            </>
          ) : (
            <Loading size={50} />
          )}

          {/* Copyright */}
          <div className={scss["powered"]}>
            <p className={scss["paragraph"]}>Powered by: </p>
            <a href="https://croonus.com/" target="_blank" rel="noopener noreferrer">
              <img src={croonusLogo} alt="Croonus logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
