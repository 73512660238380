import { Link } from "react-router-dom";

import wishListImg from "../../../../../assets/images/icons/heart.png";
import AddToCartButton from "../../../../../components/Form/AddToCartButton/AddToCartButton";
import FormWrapper, { preventDefault } from "../../../../../components/Form/FormWrapper";
import PlusMinusInput from "../../../../../components/Form/PlusMinusInput/PlusMinusInput";
import { currencyFormat } from "../../../../../helpers/functions";
import { missingImageURL } from "../../../../../helpers/images";
import useAPI from "../../../../../hooks/useAPI";
import useProductFlags from "../../../../../hooks/useProductFlags";
import Sitemap from "../../../../../routes/sitemap";
import __ from "../../../../../config/translations/serbian";
import { useState } from "react";
import Stickers from "./Stickers";
import icons from "helpers/icons";

import scss from "./ProductGridItem.module.scss";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";

/**
 * Component that shows a single product in a grid.
 *
 * @param {{}} product The product to show.
 *
 * @return {JSX.Element}
 * @constructor
 */
const ProductGridItem = ({ product, location }) => {
  const productFlags = useProductFlags();
  const api = useAPI();
  const [loading, setLoading] = useState(false);
  // Track the quantity to add to the cart
  const [quantity, setQuantity] = useState(1);
  const [isPlusMinusInputEmpty, setIsPlusMinusInputEmpty] = useState(false);

  const getQuantity = () => {
    if (quantity === 0) {
      setQuantity(1);
      return 1;
    }
    return quantity;
  };

  // Handle wishlist
  const inWishlist = productFlags.wishlist.includes(product);
  const wishlistClasses = [scss.wishlist];
  if (inWishlist) {
    wishlistClasses.push(scss.active);
  }

  const renderPrices = (item) => {


    let priceOpt = {
      status: null,
      display_original: false,
      original: null,
      display_discount: false,
      discount: null,
    };


    switch (item?.product_type) {

      case "variant":

        switch (item?.price?.min?.price?.original === item?.price?.max?.price?.original) {
          case true:
            switch (true) {
              case item?.price?.discount?.active && item?.price?.rebate?.active:
                priceOpt.status = 'rebate_discount';

                priceOpt.display_original = item?.price?.price?.original !== 0 ? true : false;
                priceOpt.original = currencyFormat(item?.price?.price?.original, item?.price?.currency);

                priceOpt.display_discount = item?.price?.price?.discount !== 0 ? true : false;
                priceOpt.discount = currencyFormat(item?.price?.price?.discount, item?.price?.currency);
                break;
              case item?.price?.discount?.active && !item?.price?.rebate?.active:
                priceOpt.status = 'discount';

                priceOpt.display_original = item?.price?.price?.original !== 0 ? true : false;
                priceOpt.original = currencyFormat(item?.price?.price?.original, item?.price?.currency);

                priceOpt.display_discount = item?.price?.price?.discount !== 0 ? true : false;
                priceOpt.discount = currencyFormat(item?.price?.price?.discount, item?.price?.currency);
                break;
              case !item?.price?.discount?.active && item?.price?.rebate?.active:
                priceOpt.status = 'rebate';

                priceOpt.display_original = item?.price?.price?.original !== 0 ? true : false;
                priceOpt.original = currencyFormat(item?.price?.price?.original, item?.price?.currency);
                break;
              default:
                priceOpt.status = 'none';

                priceOpt.display_original = item?.price?.price?.original !== 0 ? true : false;
                priceOpt.original = currencyFormat(item?.price?.price?.original, item?.price?.currency);
                break;
            }
            break;
          case false:
            let t_price = [];
            switch (true) {
              case
                item?.price?.min?.discount?.active && item?.price?.min?.rebate?.active ||
                item?.price?.max?.discount?.active && item?.price?.max?.rebate?.active:

                // Type
                priceOpt.status = 'rebate_discount';

                // Original
                priceOpt.display_original =
                  item?.price?.min?.price?.original !== 0 ||
                    item?.price?.max?.price?.original !== 0 ? true : false;

                t_price = [];
                if (item?.price?.min?.price?.original !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.min?.price?.original, item?.price?.currency)
                  );
                }
                if (item?.price?.max?.price?.original !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.max?.price?.original, item?.price?.currency)
                  );
                }
                priceOpt.original = t_price.join(' - ');

                // Discount
                priceOpt.display_discount =
                  item?.price?.min?.price?.discount !== 0 ||
                    item?.price?.max?.price?.discount !== 0 ? true : false;

                t_price = [];
                if (item?.price?.min?.price?.discount !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.min?.price?.discount, item?.price?.currency)
                  );
                }
                if (item?.price?.max?.price?.discount !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.max?.price?.discount, item?.price?.currency)
                  );
                }
                priceOpt.discount = t_price.join(' - ');

                break;
              case
                item?.price?.min?.discount?.active && !item?.price?.min?.rebate?.active ||
                item?.price?.max?.discount?.active && !item?.price?.max?.rebate?.active:

                // Type
                priceOpt.status = 'discount';

                // Original
                priceOpt.display_original =
                  item?.price?.min?.price?.original !== 0 ||
                    item?.price?.max?.price?.original !== 0 ? true : false;

                t_price = [];
                if (item?.price?.min?.price?.original !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.min?.price?.original, item?.price?.currency)
                  );
                }
                if (item?.price?.max?.price?.original !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.max?.price?.original, item?.price?.currency)
                  );
                }
                priceOpt.original = t_price.join(' - ');

                // Discount
                priceOpt.display_discount =
                  item?.price?.min?.price?.discount !== 0 ||
                    item?.price?.max?.price?.discount !== 0 ? true : false;

                t_price = [];
                if (item?.price?.min?.price?.discount !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.min?.price?.discount, item?.price?.currency)
                  );
                }
                if (item?.price?.max?.price?.discount !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.max?.price?.discount, item?.price?.currency)
                  );
                }
                priceOpt.discount = t_price.join(' - ');
                break;
              case
                !item?.price?.min?.discount?.active && item?.price?.min?.rebate?.active ||
                !item?.price?.max?.discount?.active && item?.price?.max?.rebate?.active:

                // Type
                priceOpt.status = 'rebate';

                // Original
                priceOpt.display_original =
                  item?.price?.min?.price?.original !== 0 ||
                    item?.price?.max?.price?.original !== 0 ? true : false;

                t_price = [];
                if (item?.price?.min?.price?.original !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.min?.price?.original, item?.price?.currency)
                  );
                }
                if (item?.price?.max?.price?.original !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.max?.price?.original, item?.price?.currency)
                  );
                }
                priceOpt.original = t_price.join(' - ');
                break;
              default:

                // Type
                priceOpt.status = 'none';

                // Original
                priceOpt.display_original =
                  item?.price?.min?.price?.original !== 0 ||
                    item?.price?.max?.price?.original !== 0 ? true : false;

                t_price = [];
                if (item?.price?.min?.price?.original !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.min?.price?.original, item?.price?.currency)
                  );
                }
                if (item?.price?.max?.price?.original !== 0) {
                  t_price.push(
                    currencyFormat(item?.price?.max?.price?.original, item?.price?.currency)
                  );
                }
                priceOpt.original = t_price.join(' - ');
                break;
            }
            break;
        }



        break;
      case "single":
        switch (true) {
          case item?.price?.discount?.active && item?.price?.rebate?.active:
            priceOpt.status = 'rebate_discount';

            priceOpt.display_original = item?.price?.price?.original !== 0 ? true : false;
            priceOpt.original = currencyFormat(item?.price?.price?.original, item?.price?.currency);

            priceOpt.display_discount = item?.price?.price?.discount !== 0 ? true : false;
            priceOpt.discount = currencyFormat(item?.price?.price?.discount, item?.price?.currency);
            break;
          case item?.price?.discount?.active && !item?.price?.rebate?.active:
            priceOpt.status = 'discount';

            priceOpt.display_original = item?.price?.price?.original !== 0 ? true : false;
            priceOpt.original = currencyFormat(item?.price?.price?.original, item?.price?.currency);

            priceOpt.display_discount = item?.price?.price?.discount !== 0 ? true : false;
            priceOpt.discount = currencyFormat(item?.price?.price?.discount, item?.price?.currency);
            break;
          case !item?.price?.discount?.active && item?.price?.rebate?.active:
            priceOpt.status = 'rebate';

            priceOpt.display_original = item?.price?.price?.original !== 0 ? true : false;
            priceOpt.original = currencyFormat(item?.price?.price?.original, item?.price?.currency);

            priceOpt.display_discount = item?.price?.price?.discount !== 0 ? true : false;
            priceOpt.discount = currencyFormat(item?.price?.price?.discount, item?.price?.currency);
            break;
          default:
            priceOpt.status = 'none';

            priceOpt.display_original = item?.price?.price?.original !== 0 ? true : false;
            priceOpt.original = currencyFormat(item?.price?.price?.original, item?.price?.currency);
            break;
        }
        break;

    }

    switch (priceOpt.status) {
      case 'rebate_discount':
        return (
          <>
            <Tooltip
              placement={"top"}
              arrow={true}
              title={
                <Box>
                  <Box>
                    Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                  </Box>

                  {item?.price?.rebate?.active &&
                    item?.price?.rebate?.rebates?.map((rebate) => {
                      return (
                        <Box key={item.id}>
                          Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                        </Box>
                      );
                    })}

                  {item?.price?.discount?.active &&
                    item?.price?.discount?.campaigns?.map((campaign) => {
                      return (
                        <Box key={item.id}>
                          Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                        </Box>
                      );
                    })}
                </Box>
              }>
              <p className={`text-[0.875rem] self-start text-black font-semibold py-1 ${scss.line} `}>{priceOpt.display_original ? priceOpt.original : "-"}</p>
              <div className="bg-croonus-3 self-start w-100 ">
                <span
                  className={`text-[1rem] self-start text-white font-normal py-2 ${scss.price} ${scss.discountedUnderline} `}>{priceOpt.display_discount ? priceOpt.discount : '-'}</span>
              </div>
            </Tooltip>
          </>
        );
        break;
      case 'discount':
        return (
          <>
            <Tooltip
              placement={"top"}
              arrow={true}
              title={
                <Box>
                  <Box>
                    Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                  </Box>

                  {item?.price?.rebate?.active &&
                    item?.price?.rebate?.rebates?.map((rebate) => {
                      return (
                        <Box key={item.id}>
                          Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                        </Box>
                      );
                    })}

                  {item?.price?.discount?.active &&
                    item?.price?.discount?.campaigns?.map((campaign) => {
                      return (
                        <Box key={item.id}>
                          Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                        </Box>
                      );
                    })}
                </Box>
              }>
              <p className={`text-[0.875rem] self-start text-black font-semibold py-1 ${scss.line} `}>{priceOpt.display_original ? priceOpt.original : "-"}</p>
              <div className="bg-croonus-3 self-start w-100 ">
                <span className={`text-[1rem] self-start text-white font-normal py-2 ${scss.price} ${scss.discounted}`}>{priceOpt.display_discount ? priceOpt.discount : '-'}</span>
              </div>
            </Tooltip>
          </>
        );
        break;
      case 'rebate':
        return (
          <>
            <Tooltip
              placement={"top"}
              arrow={true}
              title={
                <Box>
                  <Box>
                    Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                  </Box>

                  {item?.price?.rebate?.active &&
                    item?.price?.rebate?.rebates?.map((rebate) => {
                      return (
                        <Box key={item.id}>
                          Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                        </Box>
                      );
                    })}

                  {item?.price?.discount?.active &&
                    item?.price?.discount?.campaigns?.map((campaign) => {
                      return (
                        <Box key={item.id}>
                          Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                        </Box>
                      );
                    })}
                </Box>
              }>
              <p className={`text-[0.875rem] self-start text-black font-semibold py-1 ${scss.line} ${scss.rebateStyle} `}>{priceOpt.display_original ? priceOpt.discount : "-"}</p>
            </Tooltip>
          </>
        );
        break;
      default:
        return (
          <>
            <p className={`text-[0.875rem] self-start text-black font-semibold py-1`}>{priceOpt.display_original ? priceOpt.original : "-"}</p>
          </>
        );
        break;
    }
  };


  return (
    <div className={scss.wrapper}>
      <div className={scss.product}>
        <div className={scss.productImageContainer}>
          {/* Wishlist */}
          <button onClick={preventDefault(() => api.globalAddToWishlist(product?.basic_data?.id_product, !inWishlist))} className={wishlistClasses.join(" ")}>
            <img src={wishListImg} alt="" />
          </button>
          {/* mobile cart */}

          <button
            onClick={(e) => {
              e.preventDefault();
              setLoading(true);
              api.globalAddToCart(product, 1, loading, setLoading);
            }}
            className={scss.mobileCart}
          >
            <img src={icons.cart} alt="" />
          </button>

          {/* Additional stickers */}
          {product?.stickers?.length > 0 && <Stickers stickers={product?.stickers} />}

          {/* Image */}
          <Link to={Sitemap.catalogue.productDetails + "/" + product?.id} className={scss.productImage}>
            <div className={scss.imageContainer}>
              <img src={product?.images[0] ?? missingImageURL()} alt={product?.name} className={`${scss.image} ${product?.images[1] && scss.productImage1}`} />
              {product?.images[1] && <img src={product?.images[1]} alt={product?.name} className={`${scss.image} ${scss.productImage2}`} />}
            </div>
          </Link>
        </div>

        <Link to={Sitemap.catalogue.productDetails + "/" + product?.id} className={scss.product}>
          <span className={`${scss.category} mobile-hidden`}>{product?.categories[0]?.name}</span>
          <span className={scss.name}>{product?.basic_data?.name}</span>

          <span className={scss.code}>
            <span className={scss.codeTitle}>{__.Product.Column.Code}:</span>
            <span className={scss.codeInt}>{product?.basic_data?.sku}</span>
          </span>

          {/* Inactive price */}
          {/*{product?.price?.second ? (*/}
          {/*    <span className={scss.secondPrice}>{currencyFormat(product?.price?.second, product?.price?.currency)}</span>*/}
          {/*) : (*/}
          {/*    // <span className={scss.noSecondPrice}>&nbsp;</span>*/}
          {/*    <span className={scss.noSecondPrice}></span>*/}
          {/*)}*/}

          {/*/!* Active price *!/*/}
          {/*{product?.price?.first > 0 ? <span className={scss.price}>{currencyFormat(product?.price?.first, product?.price?.currency)}</span> : <span className={scss.price}>/</span>}*/}
          {renderPrices(product)}
        </Link>
      </div>

      {/* Add to cart */}
      <FormWrapper
        className="row pt-2"
        onSubmit={() => {

          setLoading(true);
          if (isPlusMinusInputEmpty) {
            toast.warn("Molimo unesite količinu!");
            setLoading(false);
          } else {
            api.globalAddToCart(product.id, getQuantity(), loading, setLoading);
            setQuantity(1)

          }
        }}
      >
        <div className={product?.product_type === "variant" ? "col-12" : "col-6"}>
          {product?.product_type === "variant" ? (
            <Link className={`text-decoration-none`} to={`/katalog/artikal/${product?.basic_data?.id_product}`}>
              <p className={`${scss.button1} text-center`}>Više opcija</p>
            </Link>
          ) : product?.price?.price_defined && product?.inventory?.inventory_defined ? (
            <PlusMinusInput quantity={quantity} setQuantity={setQuantity} setIsPlusMinusInputEmpty={setIsPlusMinusInputEmpty} />
          ) : null}
        </div>
        <div className={product?.price?.price_defined && product?.inventory?.inventory_defined ? `col-6` : `col-11 p-0 m-auto`}>
          {product?.price?.price_defined && product?.inventory?.inventory_defined ? (
            product?.product_type === "variant" ? null : (
              <>
                <AddToCartButton loading={loading} type={product?.inventory?.amount || product?.item?.inventory <= 0 ? "submit" : "button"} product={product} />
              </>
            )
          ) : (
            product?.product_type !== "variant" ? (
              <>
                <div className={`d-flex  flex-col ${scss.button2} h-100  items-center justify-content-center`}>
                  <Link to={`/kontakt?slug=${product?.slug ?? product?.id}`} className={`my-auto `}>
                    Pošalji upit
                  </Link>
                </div>
              </>
            ) : null
          )}
        </div>
      </FormWrapper>
    </div>
  );
};

export default ProductGridItem;
