import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

import PlusMinusInput from "components/Form/PlusMinusInput/PlusMinusInput";
import useAPI from "hooks/useAPI";
import { currencyFormat } from "helpers/functions";
import DeleteDialog from "../../../components/Dialogs/DeleteDielog";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import scss from "./CartTable.module.scss";

const ProductRow = ({ item, showImage = true, setOpenDialog }) => {
  const location = useLocation();
  const api = useAPI();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  // Track the quantity to add to the cart
  const setQuantity = (quantity) => {
    api.globalAddToCart(item?.product?.basic_data?.id_product, quantity, loading, setLoading, loadingDelete, setLoadingDelete, "", "replace");
  };
  const rabateCalc = (item) => {
    let rabate = [];
    item?.product?.price?.per_item?.rebate?.active && item?.product?.price?.per_item?.rebate?.rebates?.map((rebate) => {
      rabate.push(rebate?.calc?.calc_name);
      return null
    });
    return rabate.join("; ");
  };

  return (
    <>
      <tr className={`relative`} style={{ borderWidth: "5px", borderColor: "transparent", borderStyle: "solid" }}>
        {/*{showImage && <td className={scss.tcImage}>{item.product.image ? <img src={item.product.image} alt=""/> : "-"}</td>}*/}
        <td className={scss.tcCode}>{item?.product?.basic_data?.sku}</td>
        <td className={scss.tcTitle}>
          <Link to={`/katalog/artikal/${item?.product?.id}`} className={scss.productName} onClick={() => {
            setOpenDialog(false);
          }}>
            {item?.product?.basic_data?.name}&nbsp;
            {item?.product?.attributes_text && <>&nbsp;({item?.product?.basic_data?.attributes_text})&nbsp;</>}
          </Link>
        </td>
        <td className={scss.rebate1}>{currencyFormat(item?.product?.price?.per_item?.price_with_out_vat, item?.product?.price?.currency)}</td>
        <td className={scss.rebate2}>{item?.product?.price?.per_item?.rebate?.active ? rabateCalc(item) : "/"}</td>

        <td className={scss.rebate2}>{item?.product?.price?.per_item?.discount?.active ? '-' + currencyFormat(item?.product?.price?.per_item?.discount?.amount, item?.product?.price?.currency) : "/"}</td>

        <Tooltip
          placement={"top"}
          arrow={true}
          title={
            <Box>
              <Box>
                Regularna cena: <span>{currencyFormat(item?.product?.price?.per_item?.price_with_out_vat)}</span>
              </Box>
              {item?.product?.price?.per_item?.rebate?.active &&
                item?.product?.price?.per_item?.rebate?.rebates?.map((rebate) => {
                  return (
                    <Box key={item.product.id}>
                      Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                    </Box>
                  );
                })}
              {item?.product?.price?.per_item?.discount?.active &&
                item?.product?.price?.per_item?.discount?.campaigns?.map((campaign) => {
                  return (
                    <Box key={item.product.id}>
                      Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                    </Box>
                  );
                })}
            </Box>
          }
        >
          <td className={`${scss.tcPrice} ${item?.product?.price?.per_item?.discount?.active ? scss.discounted : ''} ${item?.product?.price?.per_item?.rebate?.active ? scss.rebateStyle : ''}  ${item?.product?.price?.per_item?.discount?.active && item?.product?.price?.per_item?.rebate?.active ? scss.discountRabate : ''}`}>{currencyFormat(item?.product?.price?.per_item?.price_subtotal, item?.product?.price?.currency)}</td>
        </Tooltip>
        <td className={scss.tcInt}>
          <div className={location.pathname === "/korpa/potvrda" ? scss.plusminus : scss.plusminus1}>
            <PlusMinusInput quantity={item?.cart?.quantity} setQuantity={setQuantity} debounceId={item?.product?.id} loading={loading} setLoading={setLoading} />
          </div>
        </td>
        <td className={scss.tcPrice2}>{currencyFormat(item?.product?.price?.cost?.subtotal, item?.product?.price?.currency)}</td>
        <td className={scss.tcActions}>
          <i
            style={{ cursor: "pointer" }}
            className="fa fa-trash"
            onClick={() => {
              setShowDialog(true);
              // setLoadingDelete(true);
              // api.globalAddToCart(item?.product?.id, 0, loading, setLoading, loadingDelete, setLoadingDelete);
            }}
          />
        </td>
        {loadingDelete && <div className={scss.loading}></div>}
      </tr>
      <DeleteDialog
        openDeleteDialog={{ show: showDialog }}
        setOpenDeleteDialog={() => setShowDialog(false)}
        handleConfirm={() => {
          setLoadingDelete(true);
          api.globalAddToCart(item?.product?.id, 0, loading, setLoading, loadingDelete, setLoadingDelete);
          setShowDialog(false);
        }}
      />
    </>
  );
};

export default ProductRow;
