import { useEffect, useState } from "react";
import "flickity-as-nav-for";
import Flickity from "react-flickity-component";
import LightBox from "components/layout/LightBox";
import "./ProductDetailsSlider.scss";
import "flickity/dist/flickity.min.css";
import useAPI from "../../hooks/useAPI";
import { useLocation } from "react-router-dom";

/**
 * Slider for product images on Single product page of B2B
 *
 * @author Aleksandar Ječmenić <aleksandar.jecmenic@croonus.com>
 */
const ProductDetailsSlider = ({ product }) => {
    const api = useAPI();

    // Big image
    const [imageId, setImageId] = useState(null);

    // Make sure the images as array
    const images = product?.gallery ?? [];
    //

    return (
        <>
            <div className="container col-12 product-details-slider-container">
                <div className="row">
                    {/* Left side thumbnail vertical slider */}
                    {images.length > 1 && (
                        <div className="col-2">
                            <Flickity
                                className="carousel-nav"
                                options={{
                                    asNavFor: ".carousel-main",
                                    draggable: true,
                                    groupCells: "100%",
                                    pageDots: false,
                                    prevNextButtons: false,
                                }}
                            >
                                {(images ?? []).map(({ image }, index) => (
                                    <div className="carousel-cell-1" key={index}>
                                        <img src={image} alt={image} />
                                    </div>
                                ))}
                            </Flickity>
                        </div>
                    )}

                    {/* Center horizontal slider */}
                    <div className={images.length > 1 ? "col-10" : "col-12"}>
                        {images.length > 0 && (
                            <Flickity
                                className="carousel-main"
                                options={{
                                    contain: true,
                                    draggable: false,
                                    wrapAround: true,
                                    autoPlay: true,
                                    pageDots: images.length > 1,
                                    prevNextButtons: false,
                                }}
                            >
                                {(images ?? []).map(({ image }, index) => (
                                    <div className="carousel-cell" key={index}>
                                        <img alt={image} src={image} onClick={() => setImageId(index)} />
                                    </div>
                                ))}
                            </Flickity>
                        )}

                        {/* !* Image not available */}
                        {images.length === 0 && <img src={api.buildPath("/configuration/images/presentation/basic/missing")} style={{ maxHeight: 300, width: "100%", objectFit: "cover" }} alt="" />}
                    </div>
                </div>
            </div>

            <LightBox openId={imageId} gallery={images} closedLightbox={setImageId} />
        </>
    );
};

export default ProductDetailsSlider;
