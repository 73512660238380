import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import MainCard from "../../components/layout/MainCard/MainCard";
import __ from "../../config/translations/serbian";
import useArray from "../../hooks/useArray";
import Sitemap from "../../routes/sitemap";
import CatalogueAside from "./Aside/CatalogueAside";
import Products from "./Products/Products";
import useAPI from "hooks/useAPI";

import Breadcrumbs from "../../components/layout/BreadCrumbs/Breadcrumbs";
import scss from "./catalogue.module.scss";
/**
 * The main page that shows multiple products.
 */
const CataloguePage = () => {
    const api = useAPI();
    const { searchTerm } = useParams();
    const { pathname } = useLocation();
    const trimmed = pathname.trim("/");
    const parts = trimmed.split("/");
    const category = parts[parts.length - 1];
    const [limit, setLimit] = useState(8);
    const id = useParams();
    const splittedId = id["*"]?.split("/");
    const categoryid = splittedId[splittedId?.length - 1];
    const { data, isLoading, ...products } = useQuery(["CataloguePageSingleCategory", categoryid], async () => await api.get(`/categories/product/single/${categoryid}`));

    const navigate = useNavigate();
    const location = useLocation();
    // The available and active filters
    const [activeFilters, setActiveFilters] = useArray([]);
    // The category we are searching for
    const [activeCategory, setActiveCategory] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [changeFilters, setChangeFilters] = useState(false);
    let title = data?.basic_data?.name;
    let categoryId = activeCategory?.id ?? 0;
    let search = "";
    const {
        data: filters,
        isFetched,
        isSuccess,
        ...basic
    } = useQuery(
        ["CataloguePage.Filters", categoryid],
        async () => {
            return await api
                .post(`/products/category/filters/${categoryid}`, {
                    filters: selectedFilters,
                })
                .then((res) => {
                    setAvailableFilters(res);
                    return res;
                });
        },
        {
            refetchOnWindowFocus: false,
        }
    );

    const [availableFilters, setAvailableFilters] = useState();
    const [lastSelectedFilterKey, setLastSelectedFilterKey] = useState("");

    useEffect(() => {
        if (changeFilters) {
            basic
                .refetch({
                    filters: selectedFilters ?? [],
                })
                .then((response) => {
                    const lastSelectedFilterValues = selectedFilters?.find((item) => {
                        return item?.column === lastSelectedFilterKey;
                    });
                    const lastSelectedFilter = availableFilters?.find((item) => {
                        return item?.key === lastSelectedFilterKey;
                    });

                    const filterLastSelectedFromResponse = response?.data?.filter((item) => {
                        return item?.key !== lastSelectedFilterKey;
                    });

                    const indexOfLastSelectedFilter = availableFilters?.findIndex((index) => {
                        return index?.key === lastSelectedFilterKey;
                    });

                    if (lastSelectedFilter && lastSelectedFilterValues?.value?.selected?.length > 0) {
                        setAvailableFilters([
                            ...filterLastSelectedFromResponse.slice(0, indexOfLastSelectedFilter),
                            lastSelectedFilter,
                            ...filterLastSelectedFromResponse.slice(indexOfLastSelectedFilter),
                        ]);
                    } else {
                        setAvailableFilters(response?.data);
                    }
                });
            setChangeFilters(false);
        }
    }, [changeFilters]);

    // Navigate to category
    const navigateToCategory = (category) => {
        navigate(Sitemap.catalogue._ + (category?.path ?? ""));
        setActiveCategory(category);
    };

    // The used search term
    if (searchTerm) {
        title = `${__.Catalogue.Search}: "${searchTerm}"`;
        categoryId = 0;
        search = searchTerm;
    }
    const params = useParams();

    let crumbs = [];
    let path = data?.breadcrumbs?.start?.slug;
    crumbs[0] = { name: "Početna", url: "/pocetna" };
    path += "/katalog/kategorija";
    for (const item of data?.breadcrumbs?.steps ?? []) {
        path += "/" + item.slug;
        crumbs.push({ name: item.name, url: path });
    }

    crumbs.push({ name: data?.breadcrumbs?.end?.name, url: "#" });

    //push selected filters to URL
    const [sort, setSort] = useState({
        field: "price",
        direction: "asc",
    });
    const [currentPage, setCurrentPage] = useState(1);

    const [newQuery, setNewQuery] = useState();
    const url = new URL(window.location.href);
    useEffect(() => {
        const updateQuery = () => {
            const filterQuery = selectedFilters
                .map((item) => {
                    if (item?.column && item?.value?.selected?.length > 0) {
                        const selectedValues = item.value.selected.map((value) => encodeURIComponent(value)).join("_");
                        return `${encodeURIComponent(item.column)}=${selectedValues}`;
                    }
                    return null;
                })
                .filter(Boolean)
                .join("&");

            const sortQuery = sort?.field && sort?.direction ? `${selectedFilters?.length === 0 ? "" : "&"}sort=${sort.field}_${sort.direction}` : "";
            const pageQuery = currentPage ? `&page=${currentPage}` : "";
            const limitQuery = limit ? `&limit=${limit}` : "";
            setNewQuery(filterQuery || sortQuery || pageQuery || limitQuery ? `?${filterQuery}${sortQuery}${pageQuery}${limitQuery}` : "");
        };

        updateQuery();
    }, [selectedFilters, sort, currentPage, limit]);
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const extractFiltersFromQuery = async () => {
            const filtersURL = url?.search?.slice(1, url?.search?.length);
            const splittedFiltersURL = filtersURL?.split("&");
            //filter out page= and sort= from filtersURL
            const splittedFiltersURL2 = splittedFiltersURL?.filter((item) => {
                return !item.includes("page=") && !item.includes("sort=") && !item.includes("limit=");
            });

            const sortQuery = searchParams?.get("sort");
            const page = searchParams?.get("page");
            const limitURLQuery = searchParams?.get("limit");
            const sortArray = sortQuery?.split("_") ?? [];
            const sortObject = {
                field: sortArray[0] ?? "",
                direction: sortArray[1] ?? "",
            };
            if (filtersURL) {
                const filterObject = splittedFiltersURL2?.map((filter) => {
                    const [column, value] = (filter ?? "").split("=");
                    const selected = (value ?? "").split("_").map((val) => {
                        return column === "pv-r|cena" ? Number(decodeURI(val)) : decodeURIComponent(val);
                    });

                    return {
                        column: decodeURIComponent(column),
                        value: {
                            selected: selected,
                        },
                    };
                });

                setSelectedFilters(filterObject);
                setSort(sortObject);
                setCurrentPage(page);
                setLimit(limitURLQuery);

                await products.refetch({
                    filters: filterObject,
                    sort: sortObject,
                    page: page,
                    limit: limitURLQuery,
                });
                setTimeout(async () => {
                    await basic.refetch({
                        filters: filterObject,
                    });
                    setChangeFilters(true);
                }, 1000);
            }

            //ukljuciti kada bude imala paginacija
            // if (page) {
            //   setPage(page);
            // }
        };
        const showButton = document.getElementById("showButton");
        if (showButton) {
            setTimeout(() => {
                showButton.click();
            }, 1000);
        }
        extractFiltersFromQuery();
    }, []);
    useEffect(() => {
        if (newQuery) {
            const path = `/katalog/kategorija/${categoryid}${newQuery}`;

            window.history.replaceState(null, "", path);
        }
    }, [newQuery]);
    return (
        <MainCard>
            {/* The list of available categories */}

            <CatalogueAside
                // activeCategory={activeCategory}
                // setActiveCategory={setActiveCategory}
                // onActivateCategory={navigateToCategory}
                setLastSelectedFilterKey={setLastSelectedFilterKey}
                lastSelectedFilterKey={lastSelectedFilterKey}
                availableFilters={availableFilters}
                setActiveFilters={setActiveFilters}
                isFetched={isFetched}
                setSelectedFilters={setSelectedFilters}
                selectedFilters={selectedFilters}
                changeFilters={changeFilters}
                setChangeFilters={setChangeFilters}
                setNewQuery={setNewQuery}
                newQuery={newQuery}
                categoryid={categoryid}
                setCurrentPage={setCurrentPage}
            />
            {!isLoading && (
                <div className={scss.breadcrumbs}>
                    <Breadcrumbs crumbs={crumbs} />
                </div>
            )}

            <Products
                title={title}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                url={`/products/category/list/${categoryid}`}
                banners={false}
                search={search}
                filters={activeFilters}
                availableFilters={availableFilters}
                setActiveFilters={setActiveFilters}
                setAvailableFilters={setAvailableFilters}
                location={location?.pathname}
                sort={sort}
                setSort={setSort}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setNewQuery={setNewQuery}
                newQuery={newQuery}
                setLimit={setLimit}
                limit={limit}
            />
        </MainCard>
    );
};

export default CataloguePage;
