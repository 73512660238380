// import scss from "./Reclamations.module.scss";
// import StaticPage from "components/StaticPage/StaticPage";
import { currencyFormat } from "helpers/functions";
import useAPI from "hooks/useAPI";
import ProfilePage from "pages/Profile/ProfilePage";
import { useEffect, useState } from "react";
// import Sitemap from "routes/sitemap";
import DataTable from "components/DataTable/DataTable";
import SearchBar from "components/UI/SearchBar";
import PaginationUI from "../../../components/UI/PaginationUI";
import { useQuery } from "react-query";
import LoadingWrapper from "components/Loading/LoadingWrapper";
import Empty from "components/Empty/Empty";
import __ from "config/translations/serbian";
import { GlobalQueries } from "../../../hooks/useRefreshQuery";

import tblFields from "./tblFields.json";
import Button from "components/Buttons/Button/Button";
import Box from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';
// import NewReclamation from "./NewReclamation/NewReclamation";
import { useNavigate } from "react-router-dom";
// import CreateForm from "components/Form/CreateForm/CreateForm";"

const Reclamations = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const apiPathListReclamations = "/reclamations/list";
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  // Load all products in the cart
  const { data: reclamations, isLoading, refetch } = useQuery([GlobalQueries.Profile.Reclamations, search, page, limit], () => api.list(apiPathListReclamations, { search, page, limit }));

  useEffect(() => {
    refetch();
  }, [page]);

  console.log("reclamations", reclamations);

  // Format the items
  const convertDate = (date) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };

    const dateObj = new Date(date);

    const formattedDate = dateObj
      .toLocaleString("sr-RS", options)
      .replace(/,/g, "")
      .replace(/(\d+)\D+(\d+)\D+(\d+)\D+(\d+):(\d+)/, "$1.$2.$3. $4:$5");
    return formattedDate;
  };

  const items = (reclamations?.items ?? []).map((item) => ({
    ...item,
    total: currencyFormat(item.total, item.currency),
    created_at: convertDate(item.created_at),
  }));

  useEffect(() => setCurrentPage(reclamations?.pagination.selected_page ?? 1), [reclamations, page, reclamations?.pagination.selected_page]);

  return (
    <ProfilePage>
      <h4 className="h-underline">{__.Reclamations.MenuTitle}</h4>

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <SearchBar onSubmit={(e) => e.preventDefault()} search={search} setSearch={setSearch} page={page} setPage={setPage} />
        <Button label="Novo" icon={<AddIcon />} onClick={() => { navigate("/reklamacije/new") }} />
      </Box>

      <LoadingWrapper isLoading={isLoading}>
        {reclamations && <DataTable columns={tblFields} data={items} apiUrl={apiPathListReclamations} />}

        {items.length === 0 && <Empty search={search} orders={items} message={__.Reclamations.Messages.ThereAreNoReclamationToShow} />}
      </LoadingWrapper>

      <PaginationUI
        activePage={currentPage}
        setPage={setPage}
        numberOfDisplayed={reclamations?.pagination?.items_per_page ?? 12}
        numberOfProducts={reclamations?.pagination.total_items ?? 0}
        changePage={() => {
          api.list(apiPathListReclamations, { search: search, limit: limit, page: page }).then((data) => { });
        }}
        page1={page}
      />
    </ProfilePage>
  );
};

export default Reclamations;
