import __ from "config/translations/serbian";
import { currencyFormat } from "helpers/functions";
import ProductRow from "./ProductRow";

import scss from "./CartTable.module.scss";

const CartTable = ({ items, cost, showImage = true, maxHeight = "300px", setOpenDialog }) => {
  return (
    <table className={scss.table}>
      <colgroup>
        <col span={1} style={{ width: "10%" }} />
        <col span={1} style={{ width: "25%" }} />
        <col span={1} style={{ width: "12%" }} />
        <col span={1} style={{ width: "12%" }} />
        <col span={1} style={{ width: "12%" }} />
        <col span={1} style={{ width: "12%" }} />
        <col span={1} style={{ width: "12%" }} />
        <col span={1} style={{ width: "5%" }} />
      </colgroup>
      <thead>
        <tr>
          {showImage && <th className={scss.tcImage}>{__.Product.Column.Image}</th>}
          <th className={scss.tcCode}>{__.Product.Column.Code}</th>
          <th className={scss.tcTitle} width="*">
            {__.Product.Column.Name}
          </th>
          <th className={`${scss.rebate1} `}>Osnovna cena</th>
          <th className={scss.rebate}>Rabat</th>
          <th className={scss.rebate}>Iznos popusta</th>
          <th className={scss.tcPrice}>Cena bez PDV-a</th>
          <th className={scss.tcInt}>Količina</th>
          <th className={scss.tcPrice2}>Ukupno bez PDV-a</th>
          <th className={scss.tcActions} />
        </tr>
      </thead>
      <tbody style={{ maxHeight: maxHeight, borderColor: "transparent", borderStyle: "solid", borderWidth: "2px" }}>
        {items.map((item) => (
          <ProductRow key={item.product.id} item={item} showImage={showImage} setOpenDialog={setOpenDialog} />
        ))}
        {/* Show total cost */}

        {cost && (
          <tr>
            <td className={scss.tcTotalLabel} colSpan={6 + !!showImage}>
              {__.Cart.Column.Total}:
            </td>

            <td className={scss.tcTotalPrice}>{currencyFormat(cost?.totals?.subtotal, cost?.currency)}</td>
            <td className={scss.tcActions} />
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default CartTable;
