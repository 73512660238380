import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import MainCard from "components/layout/MainCard/MainCard";
import LoadingWrapper from "components/Loading/LoadingWrapper";
import __ from "config/translations/serbian";
import useAPI from "hooks/useAPI";
import { GlobalQueries } from "hooks/useRefreshQuery";
import DataTable from "../../components/DataTable/DataTable";
import { currencyFormat, simpleFloat } from "../../helpers/functions";
import ListBlock from "./ListBlock/ListBlock";
import Box from "@mui/material/Box";
import PrintIcon from "@mui/icons-material/Print";

import Tooltip from "@mui/material/Tooltip";

import scss from "./OrdersDetailsPage.module.scss";
import columns from "./columns.json";
import DeleteDialog from "components/Dialogs/DeleteDielog";
import MediaPrint from "./MediaPrint/MediaPrint";
import Button from "../../components/Buttons/Button/Button";
import { toast } from "react-toastify";
import PlusMinusInput from "../../components/Form/PlusMinusInput/PlusMinusInput";

const OrdersDetailsPage = () => {
    const api = useAPI();
    const { id } = useParams();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [addableProducts, setAddableProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    // Load all products in the cart
    const { data, isLoading } = useQuery([GlobalQueries.Orders], () => api.get(`/previous-orders/${id}`));
    const [showDialog, setShowDialog] = useState(false);
    let orderToken = data?.order?.order_token;
    const rabateCalc = (item) => {
        let rabate = [];

        item?.price_campaigns.map((price_campaign) => {
            if (price_campaign?.campaign_type === "b2b_rebate") {
                rabate.push(price_campaign?.calc_name);
            }
            return null;
        });

        return rabate.join("; ");
    };

    // Show the products in the table
    let products = (data?.items ?? []).map((item) => ({
        image: item?.basic_data?.image ? <img src={item?.basic_data?.image} style={{ maxHeight: "30px" }} alt="" /> : null,
        code: item?.basic_data?.sku,
        name: item?.basic_data?.name + (item?.basic_data?.attributes_text ? " (" + item?.basic_data?.attributes_text + ")" : ""),
        slug: item?.basic_data?.slug,

        id: item?.basic_data?.id_product,
        priceWithOutVat: currencyFormat(item?.price?.price_with_out_vat, item?.price?.currency),
        rebate: rabateCalc(item),
        discount: "-" + currencyFormat(item?.price?.price_discount_amount, item?.price?.currency),
        price: currencyFormat(item?.price?.price_subtotal, item?.price?.currency),
        quantity: simpleFloat(item?.price?.quantity),
        total: currencyFormat(item?.price?.subtotal),
    }));

    const convertDate = (date) => {
        const options = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        };

        const dateObj = new Date(date);
        const formattedDate = dateObj
            .toLocaleString("sr-RS", options)
            .replace(/,/g, "")
            .replace(/(\d+)\D+(\d+)\D+(\d+)\D+(\d+):(\d+)/, "$1.$2.$3. $4:$5");
        return formattedDate;
    };

    const handlePrint = () => {
        window.print();
    };
    const { data: duplicatedProducts, refetch } = useQuery([data?.order?.order_token], () => api.get(`/previous-orders/duplicate/message/${data?.order?.order_token}`));

    const allowDuplicate = duplicatedProducts?.allow_duplicate;
    const message = duplicatedProducts?.message;
    const getAllProducts = (products, duplicatedProducts) => {
        products?.map((item) => {
            if (item?.allow_add) {
                setSelectedProducts((prev) => [...prev, item?.cart]);
            }
        });
    };

    return (
        <>
            <MainCard className="p">
                <div className="">
                    <div
                        className="page-content"
                        style={{
                            "@media print": {
                                padding: "0 !important",
                            },
                        }}
                    >
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                            <h4 className="h-underline">
                                {__.Orders.Order}: {data?.order?.slug}
                            </h4>
                        </Box>

                        <MediaPrint data={data} />

                        <LoadingWrapper isLoading={isLoading}>
                            {data && (
                                <div className={scss.wrapper}>
                                    <ListBlock
                                        additionalClass={scss.orderInfo}
                                        list={[
                                            ["Porudžbina", data?.order?.slug],
                                            ["Datum porudžbine", convertDate(data?.order?.created_at)],
                                            ["Naziv kompanije", data?.billing_address?.company_name],
                                            ["Adresa kompanije", data?.billing_address?.address],
                                            ["Grad", data?.billing_address?.town_name],
                                            ["Poštanski broj", data?.billing_address?.zip_code],
                                            ["Država", data?.billing_address?.country_name],
                                            ["PIB", data?.billing_address?.pib],
                                            ["MB", data?.billing_address?.maticni_broj],
                                            ["Robu poručio", data?.billing_address?.first_name + " " + data?.billing_address?.last_name],
                                            ["Način plaćanja", data?.order?.payment_method_name],
                                            ["Način dostave", data?.order?.delivery_method_name],
                                        ]}
                                    />

                                    <div className={scss.itemsList}>
                                        <h6 className={scss.title}>Pregled poručenih proizvoda</h6>
                                        <div className={scss.tablePrintOverflow} style={{ maxHeight: "100%", overflow: "hidden" }}>
                                            {<DataTable actions={{ previewUrl: "/katalog/artikal/" }} columns={columns} data={products} maxHeight={"40vh"} />}
                                        </div>
                                    </div>

                                    <ListBlock title="Napomena" list={[[null, data?.order?.note]]} additionalClass={scss.notePrint} />

                                    <ListBlock
                                        additionalClass={scss.priceBreakdown}
                                        title="Za uplatu"
                                        align="right"
                                        list={[
                                            [__.Checkout.Sections.PriceBreakdown.BaseAmount, currencyFormat(data?.order?.total_with_out_vat, data?.order?.currency)],
                                            [__.Checkout.Sections.PriceBreakdown.RebateAmount, "-" + currencyFormat(data?.order?.total_rebate_amount, data?.order?.currency)],
                                            [
                                                __.Checkout.Sections.PriceBreakdown.DiscountAmount,
                                                "-" + currencyFormat(Number(data?.order?.total_items_discount_amount) + Number(data?.order?.total_cart_discount_amount), data?.order?.currency),
                                            ],
                                            [__.Checkout.Sections.PriceBreakdown.SubTotal, currencyFormat(data?.order?.subtotal, data?.order?.currency)],
                                            [__.Checkout.Sections.PriceBreakdown.VATAmount, currencyFormat(data?.order?.total_vat, data?.order?.currency)],
                                            [__.Checkout.Sections.PriceBreakdown.ShippingAmount, currencyFormat(data?.order?.total_delivery_amount, data?.order?.currency)],
                                            [__.Checkout.Sections.PriceBreakdown.Total, currencyFormat(data?.order?.total, data?.order?.currency)],
                                        ]}
                                    />
                                    <ListBlock additionalClass={scss.duplicate} title={"Opcije"}>
                                        <div className={scss.buttons}>
                                            {allowDuplicate ? (
                                                <Tooltip arrow={true} title={"Izaberite koje dostupne proizvode iz ove porudžbine želite da dodate u korpu."} placement="top">
                                                    <div className={scss.tooltipDiv}>
                                                        <Button
                                                            label={"Dodaj proizvode u korpu"}
                                                            onClick={() => {
                                                                setShowDialog(true);
                                                                getAllProducts(duplicatedProducts?.items, duplicatedProducts);
                                                            }}
                                                            className={`${scss.duplicate} ${scss.buttonsStyle} mb-3`}
                                                            icon={"content_copy"}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip arrow={true} title={message} placement="top">
                                                    <div className={scss.tooltipDiv}>
                                                        <Button
                                                            disabled={true}
                                                            loading={false}
                                                            label={"Dodaj proizvode u korpu"}
                                                            onClick={() =>
                                                                toast.error(message, {
                                                                    position: "top-center",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                })
                                                            }
                                                            className={`${scss.duplicate} ${scss.buttonsStyle} mb-3`}
                                                            icon={"content_copy"}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>

                                        <Box sx={{ display: "flex", alignItems: "center" }} className={scss.orderDupPrt}>
                                            <Tooltip arrow={true} title="Štampanje dokumenta" placement="top">
                                                <div className={scss.tooltipDiv}>
                                                    <Button label={"Štampa"} className={`${scss.buttonsStyle} justify-content-start ${scss.printdiv}`} onClick={() => handlePrint()} icon={"print"}>
                                                        <PrintIcon onClick={() => handlePrint()} sx={{ cursor: "pointer", fontSize: "2rem", color: "var(--secondary-color)" }} />
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                            {/* <Button label={"Dupliraj"} onClick={() => setShowDialog(true)} className={`${scss.duplicate} ${scss.buttonsStyle}`} icon={"content_copy"} /> */}
                                            {/* <Button label={"Štampaj"} className={scss.buttonsStyle} onClick={() => handlePrint()} icon={"print"} /> */}
                                        </Box>
                                    </ListBlock>
                                </div>
                            )}
                        </LoadingWrapper>
                    </div>
                </div>
                {console.log(selectedProducts)}
                <DeleteDialog
                    loadingwidth={scss.loadingwidth}
                    openDeleteDialog={{ show: showDialog }}
                    selectedProducts={selectedProducts}
                    loading={loading}
                    getAllProducts={getAllProducts}
                    products={duplicatedProducts?.items}
                    setOpenDeleteDialog={() => setShowDialog(false)}
                    handleConfirm={async () => {
                        const checkQuantityNullValues = selectedProducts?.filter((product) => product?.quantity === null);
                        if (selectedProducts?.length > 0 && checkQuantityNullValues?.length === 0) {
                            setLoading(true);
                            await api.put(`/previous-orders/duplicate/confirm`, { cart_items: selectedProducts }).then((res) => {
                                res?.add_items?.length > 0 &&
                                    toast.success(`Uspešno dodato ${res?.add_items?.length} proizvoda`, {
                                        position: "top-center",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                    });

                                res?.error_items?.length > 0 &&
                                    res?.error_items?.forEach((item) => {
                                        toast.success(item?.message, {
                                            position: "top-center",
                                            autoClose: 3000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                        });
                                    });

                                setShowDialog(false);

                                setSelectedProducts(
                                    duplicatedProducts?.items?.map((item) => {
                                        const isAddable = item?.allow_add;
                                        if (isAddable) {
                                            return item?.cart;
                                        }
                                    })
                                );
                                setTimeout(() => {
                                    setLoading(false);
                                }, 500);
                            });
                        } else {
                            if (checkQuantityNullValues?.length > 0) {
                                toast.warn("Niste uneli količinu za sve proizvode.", {
                                    position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                            } else {
                                toast.error("Niste izabrali nijedan proizvod.", {
                                    position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                            }
                        }
                    }}
                    title={"Dodaj proizvode u korpu"}
                    description={`Označeni su svi dostupni proizvodi za dodavanje u korpu. Ukoliko neki proizvod želite da izbacite, kliknite na proizvod.`}
                    nameOfButtonDelete={"Dodaj u korpu"}
                    deafultIcon={false}
                    setSelectedProducts={setSelectedProducts}
                >
                    <i
                        onClick={() => {
                            setShowDialog(false);
                            setSelectedProducts([]);
                        }}
                        className={`fa fa-solid fa-times ${scss.exit}`}
                    ></i>
                    {duplicatedProducts?.items?.map((item, index) => {
                        const isAddable = item?.allow_add;
                        const isAdded = selectedProducts?.find((product) => product?.id_product === item?.cart?.id_product);

                        return (
                            <>
                                {isAddable && (
                                    <div key={index} className={`${scss.modalproduct} ${isAdded ? scss.added : scss.notAdded}`}>
                                        <div
                                            className={`flex align-items-center gap-2 cursor-pointer ${scss.cursor}`}
                                            onClick={(e) => {
                                                if (!isAdded) {
                                                    setSelectedProducts([...selectedProducts, item?.cart]);
                                                } else {
                                                    setSelectedProducts(selectedProducts.filter((product) => product?.id_product !== item?.cart?.id_product));
                                                }
                                            }}
                                        >
                                            <img src={item?.product?.image} alt="" width={50} height={50} />
                                            <p
                                                style={{
                                                    margin: 0,
                                                }}
                                            >
                                                {item?.product?.name} ({item?.product?.sku})
                                            </p>
                                        </div>
                                        <PlusMinusInput
                                            className={scss.quantity}
                                            quantity={item?.cart?.quantity}
                                            setIsPlusMinusInputEmpty={() => {
                                                setSelectedProducts(
                                                    selectedProducts.filter((product) => {
                                                        if (product?.id_product === item?.cart?.id_product) {
                                                            product.quantity = null;
                                                        }
                                                        return product;
                                                    })
                                                );
                                            }}
                                            setQuantity={(quantity) => {
                                                setSelectedProducts(
                                                    selectedProducts.map((product) => {
                                                        if (product?.id_product === item?.cart?.id_product) {
                                                            product.quantity = quantity;
                                                        }
                                                        return product;
                                                    })
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        );
                    })}
                </DeleteDialog>
            </MainCard>
        </>
    );
};

export default OrdersDetailsPage;
