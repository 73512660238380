import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { GlobalQueries } from "hooks/useRefreshQuery";
import useAPI from "hooks/useAPI";
import HeaderLink from "./HeaderLink/HeaderLink";
import HeaderSearch from "./HeaderSearch/HeaderSearch";
import classes from "./Header.module.scss";
import CartDialog from "components/CartDialog/CartDialog";
import icons from "helpers/icons";
import NotificationsModal from "../MobileDialogs/NotificationsModal/NotificationsModal";
import Sitemap from "routes/sitemap";
import { Icon } from "@mui/material";

/**
 * Header of B2B
 *
 * @author Aleksandar Ječmenić <aleksandar.jecmenic@croonus.com>
 */
const Header = ({ openModal, setOpenModal }) => {
    // State for search
    const [search, setSearch] = useState("");
    const { pathname } = useLocation();

    // Instance of react router for navigation
    let navigate = useNavigate();

    /**
     * Handle seacrh subit.
     *
     * @param {Event} e
     */
    // const onSearchClick = (e) => {
    //   if (search.trim() !== "" && search.trim().length > 2) {
    //     navigate(`/katalog/pretraga/` + encodeURIComponent(search));
    //     setSearch("");
    //     e.preventDefault();
    //   } else {
    //     toast.warning("Neophodno je da unesite bar 3 karaktera!");
    //     e.preventDefault();
    //   }
    // };

    const api = useAPI();

    // Load all cart items
    const { data: cart } = useQuery([GlobalQueries.Cart], () => api.list(`/cart`));
    const itemsInCart = cart?.items.length;

    // Load all wishlist items
    const { data: wishlist } = useQuery([GlobalQueries.Wishlist], () => api.list(`/wishlist`));
    const itemsInWishlist = wishlist?.items.length;

    // Load all notifications
    const { data: notifications } = useQuery([GlobalQueries.Notifications], () => api.list(`/notifications`));

    const { data: sales } = useQuery([GlobalQueries.Sales], () => api.get(`/sales-officer`));
    const unreadNotificationCount = notifications?.items.filter((notification) => !notification.seen_date).length;

    const [openCartDialog, setOpenCartDialog] = useState(false);

    const [openNotificationModal, setOpenNotificationModal] = useState(false);

    const closeAllModals = () => {
        setOpenNotificationModal(false);
    };

    const toggleNotifications = () => {
        setOpenModal("header");
        closeAllModals();
        setOpenNotificationModal(!openNotificationModal);
    };

    useEffect(() => {
        if (openModal !== "header") {
            closeAllModals();
        }
    }, [openModal]);

    useEffect(() => {
        closeAllModals();
        switch (pathname) {
            case Sitemap.notifications._:
                setOpenNotificationModal(true);
                break;
            default:
                break;
        }
    }, [pathname]);

    const headerLinks = [
        { key: "referent", onClick: () => setOpenSalesDialog(true), icon: icons.suitcase, alert: sales?.sales_officer?.name ? sales?.sales_officer?.name : null },
        { key: "lista", to: "/lista-zelja", icon: icons.wish_list, alert: itemsInWishlist > 0 ? itemsInWishlist : null },
        { key: "cart", onClick: () => setOpenCartDialog(true), icon: icons.cart, alert: itemsInCart > 0 ? itemsInCart : null },
    ];

    const headerMobileLinks = [
        {
            key: "notifikacije",
            to: Sitemap.notifications._,
            icon: icons.notifications,
            alert: unreadNotificationCount > 0 ? unreadNotificationCount : null,
        },
        { key: "lista", to: "/lista-zelja", onClick: closeAllModals, icon: icons.wish_list, alert: itemsInWishlist > 0 ? itemsInWishlist : null },
        { key: "cart", to: Sitemap.cart.checkout, onClick: closeAllModals, icon: icons.cart, alert: itemsInCart > 0 ? itemsInCart : null },
    ];

    const [openSalesDialog, setOpenSalesDialog] = useState(false);

    return (
        <>
            <header className={classes.wrapper}>
                <div className={classes.leftWrapper}>
                    <Link to={"/pocetna"} className={classes.logo}>
                        <img src={`${process.env.REACT_APP_URL}configuration/images/presentation/basic/logo`} alt="logo" />
                    </Link>
                    <HeaderSearch search={search} setSearch={setSearch} />
                </div>
                <div className={`${classes.headerLinks} ${classes.linksDesktop}`}>
                    {headerLinks.map((link) => (
                        <HeaderLink key={link.key} to={link.to} onClick={link.onClick} img={link.icon} alert={link.alert} />
                    ))}
                </div>
                <div className={`${classes.headerLinks} ${classes.linksMobile}`}>
                    {headerMobileLinks.map((link) => (
                        <HeaderLink key={link.key} to={link.to} onClick={link.onClick} img={link.icon} alert={link.alert} />
                    ))}
                </div>
            </header>
            <CartDialog openDialog={openCartDialog} setOpenDialog={setOpenCartDialog} />
            <NotificationsModal openModal={openNotificationModal} setOpenModal={setOpenNotificationModal} />
            {openSalesDialog && (
                <div className={classes.salesDialog}>
                    <div className={classes.salesDialogBox}>
                        <div className={classes.salesDialogHeader}>
                            <div className={classes.salesDialogTitle}>Prodajni predstavnik</div>
                            <div className={classes.salesDialogClose} onClick={() => setOpenSalesDialog(false)}>
                                <Icon>close</Icon>
                            </div>
                        </div>
                        {sales?.length > 0 ? (
                            <table className={classes.tbl}>
                                <colgroup>
                                    <col span={1} style={{ width: "33.33%" }} />
                                    <col span={1} style={{ width: "33.33%" }} />
                                    <col span={1} style={{ width: "33.33%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>Ime i prezime</th>
                                        <th>Telefon</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sales?.map((item) => {
                                        return (
                                            <tr className={``}>
                                                <td>
                                                    {item.first_name} {item?.last_name}
                                                </td>
                                                <td>
                                                    <a href={`tel:${item.phone}`}>{item.phone}</a>
                                                </td>
                                                <td>
                                                    <a href={`mailto:${item.email}`}>{item.email}</a>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className={classes.salesDialogNoSales}>
                                <div className={classes.salesDialogNoSalesTitle}>Trenutno nemate dodeljene prodajne predstavnike.</div>
                                {/*<div className={classes.salesDialogNoSalesText}>Molimo Vas da nas kontaktirate putem telefona ili email-a.</div>*/}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Header;
